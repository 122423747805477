function getFormattedDateTime(dateString) {
	const date = new Date(dateString);
	return date.toLocaleDateString() + " " + date.toLocaleTimeString();
}

function getFormattedDateTime2(dateString) {
	const date = new Date(dateString);
	const dateOptions = { year: "numeric", month: "long", day: "numeric" };
	const timeOptions = { hour: "numeric", minute: "2-digit", hour12: true };
	return (
		date.toLocaleDateString("en-US", dateOptions) + ", " + date.toLocaleTimeString("en-US", timeOptions)
	);
}

function getFormattedDateTime3(dateString) {
	const date = new Date(dateString);
	const options = { year: "numeric", month: "short", day: "numeric" };
	return date.toLocaleDateString("en-US", options);
}

function getFormattedDateTime4(dateString) {
	const date = new Date(dateString);

	// Options for formatting the date part
	const dateOptions = {
		weekday: "short",
		year: "numeric",
		month: "short",
		day: "numeric",
	};
	const formattedDate = date.toLocaleDateString("en-US", dateOptions);

	// Options for formatting the time part
	const timeOptions = { hour: "numeric", minute: "2-digit", hour12: true };
	const formattedTime = date.toLocaleTimeString("en-US", timeOptions);

	return `${formattedDate}, ${formattedTime}`;
}

function getDefaultEvaluationPrompt(openingDetails, interviewReportReference) {
	return `
  You will be given the interview exchange between a candidate and an interviewer for a ${
		openingDetails?.title
  } position, below delimited by triple dashes.
  The job requires ${openingDetails?.minExperience ?? 0} - ${
		openingDetails?.maxExperience ?? openingDetails?.experience
  } years of experience and the following skills delimited by triple quotes :
  """
  Skills required : ${interviewReportReference?.preferredSkills}
  """

  The conversation will be in the following format:
  - The speaker of the message : followed by his/her response
  - The speaker will either be "Candidate" or "Interviewer" followed by a semi colon : and their response/question.

  Your task is to evaluate the candidate's interview performance.
  Here are the steps to be followed:
  1) Identify each question that the interviewer has asked the candidate.
  2) Check if the candidate has provided a fitting answer to the question asked by the interviewer.
  3) If the candidate has provided an answer, evaluate his answer thoroughly. There could be cases where the candidate has provided his answer in the next response as well. Extract the candidates responses correctly.
  4) Upon examining the candidate's answer, give them points for each question out of 5 using the below rules:
    a) If the answer is correct and the candidate proves that he knows the concept and has produced a perfect answer give them a 5 for that question.
    b) For a code example, check if the answer covers all edge cases thoroughly and give score accordingly.
    b) If the candidate's response lacks clarity or if there is scope for improvement, give points accordingly.
    c) If the candidate did not provide any answer or skipped a question or has given irrelevant answers, award 0 for that question.

  Your output for each question should follow the below format:
  Question (Number) : Extract the complete question the interviewer has asked and display it.
  Evaluation : Evaluation should be strict and should cover all edge cases. Out of context answers shouldn't be considered.
  Score : Evaluation score for the candidate's response out of 5


  Follow the mentioned steps for all the questions asked in the interview and finally display a report about the candidates performance.
  Finally, give a verdict on their performance, their communication skills, their thinking ability and their total marks scored.
  
  Follow the below format to output your observations, evaluations, opinions and the Verdict:
  - average Score: Give their average score.
  - Technical Skills: Make a note on their technical Skills
  - Logical Skills: Make a note on their logical and thinking abilities
  - Communication Skills : Make a note on their communication skills, how well they are able to communicate and the brevity of the language used.
  - Grip on English : Evaluate his English speaking skills, check if the candidate's following all the correct grammar and rules.
  - Verdict -> Make a verdict if the candidate is fit for the role or not. Judge them based on all of their responses and their thinking skills.

recommend if the candidate is poor-match, below-average-match, average-match, good-match, outstanding-match.

Provide the output in JSON Format. Do not include leading or trailing backticks or any other text. Just provide the output in JSON format with the Schema defined below.

{
  "interview_questions_evaluation": [
    {
      "Question 1": " .... ",
     "evaluation": "The candidate has ....",
      "score": 3.5,
    
    },
    {
      "Question 2": "....",
      "evaluation": " ....",
      "score": 3.5
    }
  ],
  "verdict": {
    "score": “…”, //score should be out of 5
    "technical_skills": "the candidate ...",
    "logical_skills": "the candidate...",
    "communication_skills": "...",
    "grip_on_english": "..."
    "overall_verdict": "The candidate has...",
    "match_rating": “…”
    "match_rating_rationale": "..." // comment on the reasons for your rating and what could have made the candidate be in the next rating level. 
  }
}

match rating can be one of poor-match, below-average-match, average-match, good-match, outstanding-match.
verdict should include both pros and cons.

  `;
}

function getCommunicationSkillsEvaluation() {
	return `
	You will be given the interview exchange between a candidate and an interviewer below delimited by triple dashes.

  The conversation will be in the following format:
  - The speaker of the message : followed by his/her response
  - The speaker will either be "Candidate" or "Interviewer" followed by a semi colon : and their response/question.
 
  Your task is to assess the candidate's English speaking skills, including their ability to communicate with brevity and maintain proper grammar during the interview?
  For each of the responses to the interviewer's question, assess the percentage changes needed to the candidate's response i.e. how many words and phrases need to be altered to make the response grammatically correct.

  Provide the output in the following format:
  --
  Q1: percentage% changes needed. 0% being that the candidate's response is already correct.
  Improvements : Grammatical errors in the candidate's response in no more than 20 words
  --

  In the end, provide an overall accuracy, where accuracy = 100 - percentage correction needed.
  Finally, make a note on your evaluation for the given task to give it to the HR team.
  `;
}

function checkIfInvalidOrUnauthorized(message = "") {
	if (
		message &&
		typeof message === "string" &&
		(message.toLowerCase().includes("unauthorized") ||
			message.toLowerCase().includes("not authorized") ||
			message.toLowerCase().includes("missing token") ||
			message.toLowerCase().includes("expired"))
	) {
		return true;
	}
	return false;
}

function checkIf48HoursPassed(dateString) {
	if (!dateString) {
		return false;
	}
	const date = new Date(dateString);
	if (isNaN(date.getTime())) {
		return false;
	}
	const currentTime = new Date();
	const diffInHours = (currentTime - date) / (1000 * 60 * 60);
	return diffInHours >= 48;
}

function getValuesFromRHFArray(arrayOfObjects = []) {
	return arrayOfObjects.map((obj) => {
		return obj.value;
	});
}

function stripMarkdown(input = "") {
	// Remove headers
	input = input.replace(/^#.*$/gm, "");
	// Remove bold and italic
	input = input.replace(/\*\*([^*]+)\*\*/g, "$1"); // **text**
	input = input.replace(/\*([^*]+)\*/g, "$1"); // *text*
	input = input.replace(/__([^_]+)__/g, "$1"); // __text__
	input = input.replace(/_([^_]+)_/g, "$1"); // _text_
	// Remove inline code
	input = input.replace(/`([^`]+)`/g, "$1"); // `text`
	// Remove horizontal rules
	input = input.replace(/^(-\s*?){3,}$/gm, "");
	// Remove unordered lists
	input = input.replace(/^\s*[-+*]\s+/gm, "");
	// Remove ordered lists
	input = input.replace(/^\s*\d+\.\s+/gm, "");
	// Remove tables
	input = input.replace(/^\|.*\|$/gm, "");

	return input;
}

const convertFilenameToLocal = (filename) => {
	// Extract the timestamp part from the filename
	const timestamp = filename.split("/").pop().split("_")[0]; // Get the part before the underscore

	// Convert timestamp format to ISO format
	const isoTimestamp = timestamp.replace(/T(\d{2})-(\d{2})-(\d{2})-(\d{3})Z$/, "T$1:$2:$3.$4Z");

	// Create a Date object from the ISO timestamp
	const date = new Date(isoTimestamp);

	// Check for invalid date
	if (isNaN(date.getTime())) return "Invalid Date";

	// Format the date to a readable string
	const options = {
		weekday: "short",
		year: "numeric",
		month: "short",
		day: "numeric",
		hour: "numeric",
		minute: "numeric",
		hour12: true,
	};

	return date.toLocaleString("en-US", options);
};

const calculateRelativeTimes = (startTimes, fileName) => {
	// 1. Extract the timestamp from the filename
	const timestamp = fileName.split("_")[0]; // Assuming the format is `timestamp_FaceLabel.extension`

	// 2. Convert extracted timestamp to ISO format
	const isoTimestamp = timestamp.replace(/T(\d{2})-(\d{2})-(\d{2})-(\d{3})Z$/, "T$1:$2:$3.$4Z");
	const submissionTime = new Date(isoTimestamp).getTime(); // Convert to milliseconds

	let cumulativeDuration = 0;

	// 3. Loop through each session's start time
	for (let i = 0; i < startTimes.length; i++) {
		const sessionStart = new Date(startTimes[i]).getTime(); // Convert start time to milliseconds

		if (submissionTime >= sessionStart) {
			cumulativeDuration += submissionTime - sessionStart; // Accumulate the difference
		} else {
			// If the frame's timestamp is before the session's start, stop the loop
			break;
		}
	}

	// 4. Calculate relative time and format it
	const relativeTime = cumulativeDuration / 1000; // Convert to seconds
	const relativeTimeInMinsAndSeconds = `${Math.floor(relativeTime / 60)}m ${Math.floor(relativeTime % 60)}s`;

	return relativeTimeInMinsAndSeconds;
};

export {
	getFormattedDateTime,
	getFormattedDateTime2,
	getFormattedDateTime3,
	getFormattedDateTime4,
	getDefaultEvaluationPrompt,
	getCommunicationSkillsEvaluation,
	checkIfInvalidOrUnauthorized,
	checkIf48HoursPassed,
	getValuesFromRHFArray,
	stripMarkdown,
	convertFilenameToLocal,
	calculateRelativeTimes,
};
