import axios from "./axiosInstance";

async function handleCandidatePhotoUpload(formData) {
	try {
		const headers = {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		};
		const responce = await axios.post("/api/interviewReports/upload-candidate-photo", formData, headers);
		return responce;
	} catch (e) {
		const message = e?.response?.data?.message || e.message;
		throw new Error(message);
	}
}

async function fetchInterviewReportsByOpening(openingId) {
	try {
		const response = await axios.get(`/api/interviewReports/${openingId}`);
		if (response.status === 200) {
			return response.data.reports;
		} else {
			return [];
		}
	} catch (e) {
		console.log("something went wrong while fetching interview reports for the opening");
		console.log(e.message);
		const message = e?.response?.data?.message || e.message;
		throw new Error(message);
	}
}

async function fetchInterviewReportById(interviewReportId) {
	try {
		const response = await axios.get(`/api/interviewReports/interviewReport/${interviewReportId}`);
		if (response.status == 200) {
			return response.data.report;
		} else {
			return [];
		}
	} catch (e) {
		console.log("error in fetching evaluation report for the opening");
		console.log(e.message);
		const message = e?.response?.data?.message || e.message;
		throw new Error(message);
	}
}

async function getEvaluationForInterivew(
	openingId,
	interviewReportId,
	evaluationPrompt = "",
	gptModel = "gpt-4",
	isCommunicationEvaluation = false,
) {
	try {
		const response = await axios.post(`/api/interviewReports/interviewReport/evaluation`, {
			interviewReportId: interviewReportId,
			openingId: openingId,
			evaluationPrompt,
			gptModel,
			isCommunicationEvaluation,
		});
		if (response.status === 201) {
			return response.data.evaluation;
		}
	} catch (e) {
		console.log("error in fetching evaluation report for the opening");
		console.log(e.message);
		const message = e?.response?.data?.message || e.message;
		throw new Error(message);
	}
}

async function pollInterviewResultsById(interviewReportId) {
	try {
		const response = await axios.post(`/api/interviewReports/evaluationStatus`, {
			interviewReportId: interviewReportId,
		});
		console.log(response);
		if (response.status === 200) {
			return response.data;
		} else {
			return "Something went wrong";
		}
	} catch (e) {
		console.log("error in fetching evaluation report for the opening");
		console.log(e.message);
		const message = e?.response?.data?.message || e.message;
		throw new Error(message);
	}
}

async function handleInterviewProcess(formData) {
	try {
		const response = await axios.post(`/api/interviewReports/handleInterviewProcess`, formData, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		});
		return {
			failed: false,
			data: response?.data?.data?._id,
			existing: response?.data?.existing,
			message: response?.data?.message,
		};
	} catch (error) {
		console.error("Error in handleInterviewProcess:", error);
		return {
			failed: true,
			message: error.response?.data?.message || "An unexpected error occurred",
		};
	}
}

async function bulkUploadCVs(formData) {
	let response;
	try {
		response = await axios.post(`/api/interviewReports/bulkUploadCVs`, formData, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		});
		if (response.status === 201) {
			return response?.data;
		}
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				const { message } = e.response.data;
				return { failed: true, message };
			}
		} else {
			console.log(e.message);
			return { failed: true, message: e.message };
		}
	}
}

async function updateUserSkillsForInterview(preferredSkills, interviewReportId) {
	try {
		const response = await axios.post(`/api/interviewReports/updateUserSkills`, {
			preferredSkills,
			interviewReportId,
		});
		console.log(response);
		if (response.status === 200) {
			return response?.data;
		} else {
			return false;
		}
	} catch (e) {
		console.log("error in saving user details ");
		console.log(e.message);
	}
}

async function updateScheduleForCandidate(candidateId, schedule, interviewUrl) {
	try {
		const response = await axios.post(`/api/interviewReports/updateScheduleForCandidate`, {
			candidateId,
			schedule,
			interviewUrl,
		});
		console.log(response);
		if (response.status === 200) {
			return response?.data;
		} else {
			return false;
		}
	} catch (error) {
		console.log("error in saving schedule ");
		console.log(error.message);
	}
}

async function updateCandidatePostInterview(candidateId, interviewEndTime, clickedExitInterview) {
	try {
		const response = await axios.post(`/api/interviewReports/updateCandidatePostInterview`, {
			candidateId,
			interviewEndTime,
			clickedExitInterview,
		});
		console.log(response);
		if (response.status === 200) {
			return response?.data;
		} else {
			return false;
		}
	} catch (error) {
		console.log("error in saving schedule ");
		console.log(error.message);
	}
}

async function cancellation(candidateId, reason = "") {
	try {
		const response = await axios.post(`/api/interviewReports/cancel`, {
			candidateId,
			reason,
		});
		console.log(response);
		if (response.status === 200) {
			return response?.data;
		} else {
			return false;
		}
	} catch (error) {
		console.log("error in saving schedule ");
		console.log(error.message);
	}
}

async function endInterviewWithGivenId(interviewReportId) {
	try {
		if (interviewReportId) {
			const response = await axios.get(`/api/interviewReports/end/${interviewReportId}`);
			console.log("ending response from end interview");
			console.log(response);
			if (response.status == 200) {
				console.log(response);
			}
		}
	} catch (e) {
		console.log("error in ending interview ");
		console.log(e.message);
	}
}

async function updateActiveSession(
	activeSession,
	interviewReportId,
	openingTitle,
	orgName,
	proctorLink,
	emailRecipients,
	isMobile,
) {
	try {
		console.log(`activeSession in interviewReportsApi.js ${activeSession}`);
		const response = await axios.post(`/api/interviewReports/updateActiveSession`, {
			activeSession,
			interviewReportId,
			openingTitle,
			orgName,
			proctorLink,
			emailRecipients,
			isMobile,
		});
		// console.log(response);
		if (response.status === 200) {
			return response?.data;
		} else {
			return false;
		}
	} catch (error) {
		console.log("error in saving activeSession ");
		console.log(error.message);
	}
}

async function updateSecondDevice(value, type, interviewReportId) {
	try {
		const response = await axios.post(`/api/interviewReports/updateSecondDevice`, {
			value,
			type,
			interviewReportId,
		});
		console.log(response);
		if (response.status === 200) {
			// return response?.data;
			return true;
		} else {
			return false;
		}
	} catch (error) {
		console.log("error in saving secondDevice ");
		console.log(error.message);
	}
}

async function updateWelcomePageStatus(reportId) {
	try {
		const response = await axios.get(`/api/interviewReports/wp/${reportId}`);

		console.log(response);
		if (response.status === 200) {
			return true;
		} else {
			return false;
		}
	} catch (error) {
		console.log("error in saving secondDevice ");
		console.log(error.message);
	}
}

async function resumeInterviewFromGivenIndex(interviewReportId, messageIndex) {
	try {
		const response = await axios.post(`/api/interviewReports/resumeInterviewFromGivenIndex`, {
			interviewReportId: interviewReportId,
			messageIndex: messageIndex,
		});
		// console.log(response);
		if (response.status === 200) {
			return response.data;
		}
	} catch (e) {
		console.log("error in fetching evaluation report for the opening");
		console.log(e.message);
		const message = e?.response?.data?.message || e.message;
		throw new Error(message);
	}
}

async function updateInterviewEndTime(interviewReportId, endTime) {
	try {
		const response = await axios.post(`/api/interviewReports/updateInterviewEndTime`, {
			interviewReportId,
			endTime,
		});
		if (response.status === 200) {
			return true;
		} else {
			return false;
		}
	} catch (error) {
		console.log("error in saving interview end time ");
		console.log(error.message);
	}
}

async function verifyToken(token, interviewReportId) {
	try {
		const response = await axios.post(`/api/interviewReports/verifyToken`, {
			token,
			interviewReportId,
		});
		if (response.status === 200) {
			return response.data;
		}
	} catch (error) {
		// console.log(
		// 	'Error in verifying resume token:',
		// 	error.response?.status,
		// 	error.response?.data?.message
		// );
		const errorMessage = error.response?.data?.message || "Unexpected error occurred, please try again.";
		return errorMessage; // or throw new Error(errorMessage);
	}
}
async function fetchOnGoingInterviewReports() {
	try {
		const response = await axios.get(`/api/interviewReports/onGoing`);
		if (response.status === 200) {
			return response.data.reports;
		} else {
			return [];
		}
	} catch (e) {
		console.log("something went wrong while fetching onGoing interview reports ");
		console.log(e.message);
		const message = e?.response?.data?.message || e.message;
		throw new Error(message);
	}
}
async function fetchRecentInterviewReports(
	page,
	searchName = "",
	searchOrgName = "",
	interviewCompleted = false,
	pastScheduledInterviews = false,
	droppedInterviews = true,
	upcomingInterviews = false,
	starredCandidates = false,
) {
	try {
		// let response;
		const response = await axios.get(
			`/api/interviewReports/recent/${page}?searchName=${encodeURIComponent(searchName)}&searchOrgName=${encodeURIComponent(searchOrgName)}
			&interviewCompleted=${encodeURIComponent(interviewCompleted)}&pastScheduledInterviews=${encodeURIComponent(pastScheduledInterviews)}
			&upcomingInterviews=${encodeURIComponent(upcomingInterviews)}&droppedInterviews=${encodeURIComponent(droppedInterviews)}
			&starredCandidates=${encodeURIComponent(starredCandidates)}
			`,
		);
		if (response.status === 200) {
			// return[];
			// console.log(response.data);
			return response.data;
		} else {
			return [];
		}
	} catch (e) {
		console.log("Something went wrong while fetching recent interview reports");
		console.log(e.message);
		const message = e?.response?.data?.message || e.message;
		throw new Error(message);
	}
}
async function updateUserResume(formData) {
	let response;
	try {
		response = await axios.post(`/api/interviewReports/cv`, formData, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		});
		if (response.status === 201) {
			return response?.data;
		}
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				const { message } = e.response.data;
				return { failed: true, message };
			}
		} else {
			console.log(e.message);
			return { failed: true, message: e.message };
		}
	}
}
const updateCandidateDetails = async (formData) => {
	let response;
	try {
		response = await axios.post(`/api/interviewReports/updateCandidateDetails`, formData, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		});
		if (response.status === 200) {
			return response?.data;
		}
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				const { message } = e.response.data;
				return { failed: true, message };
			}
		} else {
			console.log(e.message);
			return { failed: true, message: e.message };
		}
	}
};

const updateCandidateDetailss = async (formData) => {
	let response;
	try {
		response = await axios.post(`/api/interviewReports/updateCandidateDetailss`, formData, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		});
		if (response.status === 200) {
			return response?.data;
		}
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				const { message } = e.response.data;
				return { failed: true, message };
			}
		} else {
			console.log(e.message);
			return { failed: true, message: e.message };
		}
	}
};

async function saveUserDetailsForAnInterivewByAdmin(formData) {
	let response;
	try {
		response = await axios.post(`/api/interviewReports/createUserByAdmin`, formData, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		});
		if (response.status === 201) {
			return response?.data;
		}
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				const { message } = e.response.data;
				return { failed: true, message };
			}
		} else {
			console.log(e.message);
			return { failed: true, message: e.message };
		}
	}
}

async function updateCustomDataForInterviewReport(data) {
	try {
		const response = await axios.put(`/api/interviewReports/custom-field-data`, data);
		if (response.status === 200) {
			return response.data.report;
		}
	} catch (e) {
		console.log("error in updating custom fields value");
		console.log(e.message);
		const message = e?.response?.data?.message || e.message;
		throw new Error(message);
	}
}

async function updateComments(data) {
	try {
		const response = await axios.put(`/api/interviewReports/update-comment`, data);
		if (response.status === 200) {
			return response.data;
		}
	} catch (e) {
		console.log("error in adding comments");
		console.log(e.message);
		const message = e?.response?.data?.message || e.message;
		throw new Error(message);
	}
}

async function fetchInterviewReportByIdForInterview(interviewReportId) {
	try {
		const response = await axios.get(
			`/api/interviewReports/interviewReport/interview/${interviewReportId}`,
		);
		return response.data.report;
	} catch (e) {
		const message = e?.response?.data?.message || e.message;
		throw new Error(message);
	}
}

async function searchOrganizationName(orgName) {
	try {
		const response = await axios.get(`/api/interviewReports/getOrganizationName/${orgName}`);
		return response.data;
	} catch (e) {
		console.log("error in fetch organization name");
		console.log(e.message);
		const message = e?.response?.data?.message || e.message;
		throw new Error(message);
	}
}

export {
	handleCandidatePhotoUpload,
	fetchInterviewReportsByOpening,
	updateUserSkillsForInterview,
	fetchInterviewReportById,
	getEvaluationForInterivew,
	pollInterviewResultsById,
	handleInterviewProcess,
	saveUserDetailsForAnInterivewByAdmin,
	updateCandidateDetails,
	updateCandidateDetailss,
	updateUserResume,
	bulkUploadCVs,
	updateScheduleForCandidate,
	updateCandidatePostInterview,
	cancellation,
	endInterviewWithGivenId,
	updateActiveSession,
	updateSecondDevice,
	updateWelcomePageStatus,
	resumeInterviewFromGivenIndex,
	updateInterviewEndTime,
	verifyToken,
	fetchOnGoingInterviewReports,
	fetchRecentInterviewReports,
	updateCustomDataForInterviewReport,
	updateComments,
	fetchInterviewReportByIdForInterview,
	searchOrganizationName,
};
