import axios from "axios";

export const handleUpload = (newfiles, selectedLanguageISO639Code, interviewReportId) => {
	let formData = new FormData();
	formData.append("file", newfiles);
	formData.append("language", selectedLanguageISO639Code);
	formData.append("interviewReportId", interviewReportId);
	return new Promise((resolve, reject) => {
		axios({
			url: `/api/openings/riktam/openapi/v1/createtransaction`,
			method: "POST",
			data: formData,
		})
			.then((res) => {
				resolve(res);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const handleRhubarb = (newfiles) => {
	let formData = new FormData();
	formData.append("file", newfiles);
	return new Promise((resolve, reject) => {
		axios({
			url: `/api/openings/riktam/openapi/v1/rhubarb`,
			method: "POST",
			data: formData,
		})
			.then((res) => {
				resolve(res);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const handleUploadAnswers = (
	messages,
	openingId,
	userInterviewReportId,
	isTechnical,
	nonTechFunctionCallObject,
	isAutoSkipped = false,
) => {
	return new Promise((resolve, reject) => {
		axios({
			url: `/api/openings/riktam/openapi/v1/createChatCompletion`,
			method: "POST",
			headers: {
				"Access-Control-Allow-Origin": true,
			},
			data: {
				messages,
				openingId,
				interviewReportId: userInterviewReportId,
				isTechnical: isTechnical,
				nonTechFunctionCallObject: nonTechFunctionCallObject,
				isAutoSkipped: isAutoSkipped,
			},
		})
			.then((res) => {
				console.log("response from chat completion", res);
				resolve(res.data);
			})
			.catch((err) => {
				console.log("errr", err);
				reject(err);
			});
	});
};

export const gptVision = async (imgBlob, _id, timestamp) => {
	try {
		const formData = new FormData();
		formData.append("imgBlob", imgBlob);
		formData.append("_id", _id);
		formData.append("timestamp", timestamp);
		const response = await axios.post(`/api/openings/riktam/openapi/v1/gptVision`, formData, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		});
		if (response.status === 200) {
			return response?.data;
		} else {
			return false;
		}
	} catch (error) {
		console.log("error in joining meeting");
		console.log(error.message);
	}
};

export const unscriptedELSA = async (Mp3File, interviewReportId) => {
	let formData = new FormData();
	formData.append("file", Mp3File);
	formData.append("interviewReportId", interviewReportId);
	return new Promise((resolve, reject) => {
		axios({
			url: `/api/openings/riktam/openapi/v1/unscriptedELSA`,
			method: "POST",
			data: formData,
		})
			.then((res) => {
				resolve(res);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const checkTranscript = (text, interviewReportId, idx) => {
	return new Promise((resolve, reject) => {
		axios({
			url: `/api/openings/riktam/openapi/v1/checkTranscript`,
			method: "POST",
			data: {
				textToCompare: text,
				interviewReportId: interviewReportId,
				idx: idx,
			},
		})
			.then((res) => {
				resolve(res);
			})
			.catch((err) => {
				reject(err);
			});
	});
};
