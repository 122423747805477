export const availableLanguagesMap = {
	// af: "Afrikaans",
	ar: "Arabic",
	// bg: "Bulgarian",
	// ca: "Catalan",
	// cs: "Czech",
	// da: "Danish",
	// nl: "Dutch",
	en: "English",
	// fi: "Finnish",
	fr: "French",
	// gl: "Galician",
	// de: "German",
	// el: "Greek",
	// he: "Hebrew",
	hi: "Hindi",
	// hu: "Hungarian",
	// is: "Icelandic",
	id: "Indonesian",
	it: "Italian",
	ja: "Japanese",
	kn: "Kannada",
	ko: "Korean",
	// lv: "Latvian",
	// lt: "Lithuanian",
	ms: "Malay",
	mr: "Marathi",
	// no: "Norwegian",
	pl: "Polish",
	// pt: "Portuguese",
	// ro: "Romanian",
	ru: "Russian",
	// sr: "Serbian",
	// sk: "Slovak",
	es: "Spanish",
	// sv: "Swedish",
	ta: "Tamil",
	th: "Thai",
	// tr: "Turkish",
	// uk: "Ukrainian",
	// vi: "Vietnamese",
};

export const allLanguagesWithISO639Codes = Object.keys(availableLanguagesMap);

export const promptHelperForLanguages = (languageCode = "en") => {
	const language = availableLanguagesMap[languageCode] ?? "English";
	const promptToReturn = language === "English" ? "English language" : `${language} and English languages`;
	return promptToReturn;
};

export const availableCustomOptions = {
	0: "Text",
	1: "Drop Down",
};

export const availableColors = [
	"bg-green-300",
	"bg-blue-300",
	"bg-red-300",
	"bg-indigo-300",
	"bg-orange-300",
	"bg-violet-300",
	"bg-pink-300",
	"bg-teal-300",
	"bg-sky-300",
	"bg-amber-300",
	"bg-gray-300",
	"bg-fuchsia-300",
	"bg-purple-300",
	"bg-rose-300",
	"bg-cyan-300",
	"bg-slate-300",
	"bg-red-500",
	"bg-emerald-300",
];

export const dummySqlTable = `
EmployeeID | FirstName | LastName | DepartmentID | Salary
1          | John      | Doe      | 1            | 50000
2          | Jane      | Smith    | 2            | 60000
3          | Jim       | Brown    | 1            | 55000
4          | Jake      | Wilson   | 3            | 70000
5          | Jill      | Johnson  | 2            | 65000

DepartmentID | DepartmentName
1            | HR
2            | IT
3            | Finance

1. Write a query to retrieve the full names (FirstName and LastName) and department names of all employees.
2. Write a query to find the average salary of employees in each department.
    `;
