const getFieldsArray = (customFields = [], forFilterPanel = false) => {
    return [
        {
            value: "CandidateId",
            disabled: false,
            type: "report", // used while showing columns in criteria modal in actions
            variant: "text", // used while rendering input fields in criteria modal in actions
            operatorType: "text", // used while rendering operator dropdown in criteria modal in actions
            enabledFor: ["system", "custom", "info"],
        },
        {
            value: "Name",
            disabled: false,
            type: "report",
            variant: "text",
            operatorType: "text",
            enabledFor: ["system", "custom", "criteria", "eventType3", "info", "applyFilters"],
        },
        {
            value: "Email",
            disabled: false,
            type: "report",
            variant: "text",
            operatorType: "text",
            enabledFor: ["system", "custom", "criteria", "eventType3", "info", "applyFilters"],
        },
        {
            value: "PhoneNumber",
            disabled: false,
            type: "report",
            variant: "number",
            operatorType: "number",
            enabledFor: ["system", "custom", "criteria", "eventType3", "info", "applyFilters"],
        },
        {
            value: "Experience",
            disabled: false,
            type: "report",
            variant: "number",
            operatorType: "number",
            enabledFor: ["system", "custom", "criteria", "eventType3", "info", "applyFilters"],
        },
        {
            value: "Score",
            disabled: false,
            type: "report",
            variant: "number",
            operatorType: "number",
            enabledFor: ["system", "custom", "criteria", "info", "applyFilters"],
        },
        {
            value: "TrustScore",
            disabled: false,
            type: "report",
            variant: "number",
            operatorType: "number",
            enabledFor: ["system", "custom", "criteria", "info", "applyFilters"],
        },
        {
            value: "InterviewDate",
            disabled: false,
            type: "report",
            variant: "date",
            operatorType: "date",
            enabledFor: ["system", "custom", "criteria", "info", "applyFilters"],
        },
        {
            value: "ScheduleAsString",
            disabled: false,
            type: "report",
            variant: "text",
            operatorType: "text",
            enabledFor: ["system", "custom", "info"],
        },
        {
            value: "ScheduleAsDate",
            disabled: false,
            type: "report",
            variant: "date",
            operatorType: "date",
            enabledFor: ["system", "custom", "criteria", "info", "applyFilters"],
        },
        {
            value: "InterviewStatus",
            disabled: false,
            type: "report",
            variant: "dropdown",
            operatorType: "text",
            enabledFor: ["system", "custom", "criteria", "eventType3", "info", "applyFilters"],
            options: forFilterPanel ?
                [
                    {
                        value: "Scheduled",
                        disabled: false,
                    },
                    {
                        value: "Completed",
                        disabled: false,
                    },
                    {
                        value: "Cancelled",
                        disabled: false,
                    },
                    {
                        value: "Dropped",
                        disabled: false,
                    },
                    {
                        value: "Ongoing",
                        disabled: false,
                    },
                    {
                        value: "New",
                        disabled: false,
                    },
                ]
                : [
                    {
                        value: "Candidate Terminated Prematurely",
                        disabled: false,
                    },
                    {
                        value: "Interview Completed",
                        disabled: false,
                    },
                    {
                        value: "Interview Scheduled",
                        disabled: false,
                    },
                    {
                        value: "Interview Not Started",
                        disabled: false,
                    },
                    {
                        value: "Interview Cancelled",
                        disabled: false,
                    },
                    {
                        value: "Interview Dropped",
                        disabled: false,
                    },
                    {
                        value: "Interview Ongoing",
                        disabled: false,
                    },
                    {
                        value: "New",
                        disabled: false,
                    },
                ],
        },
        {
            value: "CreatedByAdmin",
            disabled: false,
            type: "report",
            variant: "boolean",
            operatorType: "boolean",
            enabledFor: ["system", "custom", "criteria"],
            options: [
                {
                    value: "true",
                    disabled: false,
                },
                {
                    value: "false",
                    disabled: false,
                },
            ],
        },
        {
            value: "Recording",
            disabled: false,
            type: "report",
            variant: "text",
            operatorType: "text",
            enabledFor: ["system", "custom", "info"],
        },
        {
            value: "EvaluationUrl",
            disabled: false,
            type: "report",
            variant: "text",
            operatorType: "text",
            enabledFor: ["system", "custom", "info"],
        },
        {
            value: "Resume",
            disabled: false,
            type: "report",
            variant: "text",
            operatorType: "text",
            enabledFor: ["system", "custom", "criteria", "eventType3", "info"],
        },
        ...customFields.map((el) => {
            let variant = "text";
            let options = [];
            switch (Number(el.type)) {
                case 0:
                    variant = "text";
                    break;
                case 1:
                    variant = "dropdown";
                    el.config?.dropDownValues?.forEach((val) => {
                        options.push({
                            value: val.id,
                            id: val.id,
                            label: val.value,
                            disabled: false,
                        });
                    });
                    break;
                default:
                    variant = "unknown";
                    break;
            }
            let field = {
                value: el._id,
                label: el.fieldName,
                disabled: false,
                id: el._id,
                type: "report",
                variant: variant,
                operatorType: "text",
                enabledFor: ["system", "custom", "criteria", "eventType3", "info", "applyFilters"],
            };
            if (variant === "dropdown") {
                field.options = options;
            }
            return field;
        }),
        { value: "Divider", disabled: true, isDivider: true, enabledFor: ["system", "custom"] },
        {
            value: "OpeningId",
            disabled: false,
            type: "opening",
            variant: "text",
            operatorType: "text",
            enabledFor: ["system", "custom", "info"],
        },
        {
            value: "InterviewUrl",
            disabled: false,
            type: "opening",
            variant: "text",
            operatorType: "text",
            enabledFor: ["system", "custom", "info"],
        },
        {
            value: "OpeningTitle",
            disabled: false,
            type: "opening",
            variant: "text",
            operatorType: "text",
            enabledFor: ["system", "custom", "info"],
        },
        {
            value: "supportName",
            disabled: false,
            type: "opening",
            variant: "text",
            operatorType: "text",
            enabledFor: ["system", "custom", "info"],
        },
        {
            value: "SupportEmail",
            disabled: false,
            type: "opening",
            variant: "text",
            operatorType: "text",
            enabledFor: ["system", "custom", "info"],
        },
        {
            value: "SupportPhone",
            disabled: false,
            type: "opening",
            variant: "number",
            operatorType: "number",
            enabledFor: ["system", "custom", "info"],
        },
        { value: "Divider", disabled: true, isDivider: true, enabledFor: ["system", "custom"] },
        {
            value: "AdminEmail",
            disabled: false,
            type: "global",
            variant: "text",
            operatorType: "text",
            enabledFor: ["system", "custom", "info"],
        },
        {
            value: "AdminUsername",
            disabled: false,
            type: "global",
            variant: "text",
            operatorType: "text",
            enabledFor: ["system", "custom", "info"],
        },
        {
            value: "OrganizationName",
            disabled: false,
            type: "global",
            variant: "text",
            operatorType: "text",
            enabledFor: ["system", "custom", "info"],
        },
        {
            value: "CurrentDateTime",
            disabled: false,
            type: "global",
            variant: "text",
            operatorType: "text",
            enabledFor: ["system", "custom", "info"],
        },
    ]
}

export default getFieldsArray;