import { Button, Modal, Tooltip } from "@mui/material";
import { LocalizationProvider, StaticDateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { updateCandidate } from "../../../app/actions/candidates/candidate";
import { updateScheduleForCandidate } from "../../../utilities/interviewReportsApi";
import { useDispatch } from "react-redux";
import React from "react";

const ScheduleRenderer = ({ params, openingData }) => {
	let interviewReport = params.row;
	const [modalState, setModalState] = React.useState();
	const [saving, setSaving] = React.useState(false);
	const dispatch = useDispatch();

	const handleSchedule = async (val) => {
		try {
			setSaving(true);
			let formattedDate = dayjs(val).toISOString();
			const interviewUrl = `${window.origin}/interview/${openingData?._id}/start/${interviewReport._id}`;
			const res = await updateScheduleForCandidate(
				interviewReport._id,
				formattedDate,
				interviewUrl,
				openingData?.title,
				"empty",
				false,
				openingData?.proctoring,
			);
			if (res?.message === "updated") {
				dispatch(updateCandidate(res?.interviewReportData));
				toast.success("Interview scheduled successfully. An email has been sent to the candidate.");
			} else {
				toast.error("Failed to schedule interview. Please try again.");
			}
		} catch (error) {
			console.error("Error scheduling interview:", error);
		} finally {
			setSaving(false);
			setModalState(false);
		}
	};

	return (
		<div className="flex items-center justify-center w-full h-full flex-wrap gap-1">
			<Modal
				open={modalState}
				onClose={() => {
					setModalState(false);
				}}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<div
					className="bg-white p-6 pb-2 rounded-lg shadow-lg flex"
					style={{
						position: "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -50%)",
						bgcolor: "background.paper",
						boxShadow: 24,
					}}
				>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<StaticDateTimePicker
							onAccept={(val) => handleSchedule(val)}
							onClose={() => setModalState(false)}
							defaultValue={dayjs(
								interviewReport.schedule ? interviewReport.schedule : new Date(),
							)}
						/>
					</LocalizationProvider>
				</div>
			</Modal>
			{interviewReport?.interviewCompleted ? (
				"Interview Completed"
			) : (
				<>
					<Tooltip title="Schedule Interview" arrow>
						<Button
							className="normal-case shadow-none text-white"
							variant="contained"
							startIcon={<i className="fas scale-75 fa-calendar-check"></i>}
							size="small"
							onClick={() => setModalState(true)}
							disabled={saving}
							sx={{
								backgroundColor: "#10B981",
								":hover": {
									backgroundColor: "#047857",
								},
							}}
						>
							{interviewReport?.schedule ? "Reschedule" : "Schedule"}
						</Button>
					</Tooltip>
					<Tooltip title="Copy Interview Link" arrow>
						<Button
							className="shadow-none normal-case bg-blue-500 hover:bg-blue-600"
							variant="contained"
							startIcon={<i className="fas scale-75 fa-link"></i>}
							size="small"
							onClick={() => {
								const urlToShare = interviewReport?.resumeToken
									? `${window.location.protocol}//${window.location.host}/interview/${openingData?._id}/start/${interviewReport._id}/${interviewReport?.resumeToken}`
									: `${window.location.protocol}//${window.location.host}/interview/${openingData?._id}/start/${interviewReport._id}`;
								navigator.clipboard.writeText(urlToShare);
								toast.success("Link copied to clipboard");
							}}
						>
							Share Link
						</Button>
					</Tooltip>
				</>
			)}
		</div>
	);
};

export default ScheduleRenderer;
