import React, { useRef, useState, useEffect } from "react";
import { Button, Box, Snackbar, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { handleCandidatePhotoUpload } from "../utilities/interviewReportsApi";
import { clientLogger } from "../utilities/loggingApi";

export const CandidatePhotoCapture = ({
	userinterviewreport_id,
	isCreatedByAdmin,
	isCandidatePhotoRequired,
	handleAfterCandidatePhotoCapture,
}) => {
	const [candidatePhotoSrc, setCandidatePhotoSrc] = useState(null);
	const candidatePhotoRef = useRef(null);
	const [loading, setLoading] = useState(false);
	const [snackBarMessage, setSnackBarMessage] = useState("");
	const [snackbarOpen, setSnackBarOpen] = useState(false);

	const startWebcam = (ref) => {
		if (ref.current) {
			navigator.mediaDevices
				.getUserMedia({ video: true })
				.then((stream) => {
					ref.current.srcObject = stream;
				})
				.catch((err) => {
					console.error("Error accessing webcam: ", err);
				});
		}
	};

	useEffect(() => {
		startWebcam(candidatePhotoRef);
	}, [candidatePhotoSrc]);

	const capturePhoto = (setSrc, ref) => {
		const video = ref.current;
		const canvas = document.createElement("canvas");
		canvas.width = video.videoWidth;
		canvas.height = video.videoHeight;
		const ctx = canvas.getContext("2d");
		ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
		setSrc(canvas.toDataURL("image/png"));
	};

	const deleteCapturePhoto = (setSrc) => {
		setSrc(null);
	};

	const dataURLtoFile = (dataurl, filename) => {
		const arr = dataurl.split(",");
		const mime = arr[0].match(/:(.*?);/)[1];
		const bstr = atob(arr[1]);
		let n = bstr.length;
		const u8arr = new Uint8Array(n);
		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}
		return new File([u8arr], filename, { type: mime });
	};

	const verifyPhoto = async () => {
		try {
			if (loading) return;

			if (candidatePhotoSrc === null) {
				setSnackBarOpen(true);
				setSnackBarMessage("Your photo is required.");
				return;
			}

			if (userinterviewreport_id === undefined || userinterviewreport_id === null) {
				setSnackBarOpen(true);
				setSnackBarMessage("Error verifying photos. Please try again.");
				return;
			}
			clientLogger("The candidate clicks on the verify identity button.");
			setLoading(true);
			const candidatePhotoFile = dataURLtoFile(candidatePhotoSrc, "candidatePhoto.png");
			const formData = new FormData();
			formData.append("candidatePhoto", candidatePhotoFile);
			formData.append("userinterviewreport_id", userinterviewreport_id);

			let response = await handleCandidatePhotoUpload(formData);

			setLoading(false);
			if (response.status === 200) {
				handleAfterCandidatePhotoCapture();
				clientLogger(response?.data?.message);
			} else {
				setSnackBarOpen(true);
				setSnackBarMessage(response?.data?.message);
				clientLogger(response?.data?.message);
			}
		} catch (error) {
			setLoading(false);
			let result = error?.message ? error?.message : "Error uploading photo. Please try again.";
			clientLogger(result);
			setSnackBarMessage(result);
			setSnackBarOpen(true);
		}
	};

	const onSkip = () => {
		handleAfterCandidatePhotoCapture();
	};

	const handleSnackBarClose = () => {
		setSnackBarOpen(false);
	};

	const action = (
		<React.Fragment>
			<IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackBarClose}>
				<CloseIcon fontSize="small" />
			</IconButton>
		</React.Fragment>
	);

	return (
		<div className="flex flex-col items-center justify-center bg-gray-200 p-4">
			<Box className="flex justify-center space-x-4 mt-2">
				{isCreatedByAdmin && <Box id="step1Bar" className="bg-gray-300 rounded h-2 w-24"></Box>}
				<Box id="step2Bar" className="bg-gray-300 rounded h-2 w-24"></Box>
				<Box id="step3Bar" className="bg-gray-300 rounded h-2 w-24"></Box>
				<Box id="step4Bar" className="bg-green-500 rounded h-2 w-24"></Box>
			</Box>

			<div className="flex flex-col justify-center items-center mt-10">
				<div className="bg-white p-7 rounded shadow-md w-full max-w-2xl">
					<h2 className="text-center text-lg font-semibold mb-2">Candidate Photo Capture</h2>
					<p className="text-center text-gray-500 mb-1">
						To ensure a smooth and secure interview process, we kindly ask you to capture a clear
						picture of yourself before your interview.
					</p>
					<div className="flex flex-row flex-wrap space-x-6">
						<div
							className="flex-1 flex flex-col items-center"
							style={{ border: "1px solid gray", borderRadius: "5px" }}
						>
							<div className="border border-gray-300 rounded-lg overflow-hidden h-80 w-full flex items-center justify-center relative">
								{!candidatePhotoSrc ? (
									<video
										className="w-full h-full"
										style={{ transform: "scaleX(-1)" }}
										ref={candidatePhotoRef}
										autoPlay
									></video>
								) : (
									<img
										src={candidatePhotoSrc}
										alt="ID Preview"
										className="object-contain w-full h-full"
										style={{
											maxWidth: "100%",
											maxHeight: "100%",
											transform: "scaleX(-1)",
										}}
									/>
								)}
								<div className="absolute bottom-1 flex gap-4">
									{!candidatePhotoSrc ? (
										<Button
											variant="contained"
											size="small"
											onClick={() =>
												capturePhoto(setCandidatePhotoSrc, candidatePhotoRef)
											}
											className="px-6 py-2 rounded"
											sx={{ textTransform: "none" }}
											disabled={loading}
										>
											Capture
										</Button>
									) : (
										<>
											<Button
												variant="contained"
												className="px-6 py-2 rounded"
												size="small"
												disabled={loading}
												onClick={verifyPhoto}
												sx={{ textTransform: "none" }}
											>
												{loading ? (
													<div>
														Uploading...{" "}
														<i className="fas fa-circle-notch fa-spin fa-xs"></i>
													</div>
												) : (
													<div>
														Upload{" "}
														<i className="fas fa-arrow-right fa-xs ml-1"></i>
													</div>
												)}
											</Button>
											<Button
												variant="contained"
												className="px-6 py-2 rounded bg-white border border-red-500 text-red-500 hover:bg-red-500 hover:text-white"
												size="small"
												sx={{ textTransform: "none" }}
												onClick={() => deleteCapturePhoto(setCandidatePhotoSrc)}
												disabled={loading}
											>
												Re-Capture
											</Button>
										</>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="flex flex-col gap-4 items-center mt-5 mb-8">
					{!isCandidatePhotoRequired && (
						<Button
							variant="text"
							className="px-6 py-2 rounded"
							size="small"
							disabled={loading}
							onClick={onSkip}
							sx={{ textTransform: "none" }}
						>
							Skip
						</Button>
					)}
				</div>
			</div>

			<Snackbar
				open={snackbarOpen}
				autoHideDuration={4000}
				onClose={handleSnackBarClose}
				message={snackBarMessage}
				action={action}
			/>
		</div>
	);
};
