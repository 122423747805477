import React from "react";
import { Box, Typography } from "@mui/material";
import EventsModal from "../EventsModal";
import ActionsMenu from "../ActionsMenu";
import { useSelector } from "react-redux";
import DownloadCandidates from "../downloadCandidates/DownloadCandidates";
import HideUnhideButton from "../hideUnhideButton/HideUnhideButton";
import SendEmail from "../directEmail/SendEmail";
import AddCustomFeilds from "./AddCustomFeilds";
import AddCandidates from "./AddCandidates";
import UploadResumes from "./UploadResumes";

const ToolBar = () => {
	const { loading: fetchingOpeningData } = useSelector((state) => state.openingDetails);
	const { isFetchingCandidates: loadingReports } = useSelector((state) => state.candidates);
	const { candidates } = useSelector((state) => state.candidates);
	return (
		<>
			<Box className="flex justify-between items-center mb-4">
				<Typography variant="h3" className="text-lg font-semibold">
					Candidates
				</Typography>
				<Box className="flex items-center justify-end flex-wrap gap-2 text-sm ml-2">
					{(!fetchingOpeningData || !loadingReports) && <AddCustomFeilds />}
					{(candidates && candidates.length) > 0 && <DownloadCandidates />}
					<AddCandidates />
					<UploadResumes />
					<EventsModal onEventModalClose={(data) => null} />
					<ActionsMenu />
					<HideUnhideButton />
					<SendEmail onSendEmailModalClose={() => {}} />
				</Box>
			</Box>
		</>
	);
};

export default ToolBar;
