import { Button, Chip, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { checkIf48HoursPassed, getFormattedDateTime4 } from "../../../utilities/utilityFunctions";
import { Cancel } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { renderConfirmationDialog } from "../../confirmation/ConfirmationDialog";
import { cancellation } from "../../../utilities/interviewReportsApi";
import { updateCandidate } from "../../../app/actions/candidates/candidate";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const InterviewStatusRenderer = ({ params, openingData }) => {
	const dispatch = useDispatch();

	const handleCancelSchedule = () => {
		renderConfirmationDialog(
			"Are you sure you want to cancel this scheduled interview?",
			() => {},
			cancel,
			true,
			0,
			"Cancel Scheduled Interview",
		);
	};

	const cancel = async () => {
		const res = await cancellation(
			interviewReport?._id,
			openingData?.title,
			// cancelStates[interviewReport?._id]?.reasonForCancellation,
		);
		if (res?.message === "cancelled") {
			dispatch(updateCandidate(res?.interviewReportData));
			toast.success("Interview Cancelled. The candidate has been notified by email.");
		} else {
			toast.error("Error cancelling interview. Please try again later.");
		}
	};

	let interviewReport = params.row;
	return (
		<div className="text-center flex h-full w-full justify-center">
			<div className="flex w-full h-full items-center justify-center">
				{interviewReport?.interviewCompleted ? (
					<span className="text-gray-400">
						{interviewReport?.clickedExitInterview ? (
							<Tooltip title="Candidate clicked the exit interview button." arrow>
								<Chip
									className="font-semibold bg-yellow-200 text-yellow-800"
									label="Candidate Terminated Prematurely"
								/>
							</Tooltip>
						) : (
							<Chip
								label="Interview Completed"
								className="bg-green-200 text-green-800 font-semibold"
							/>
						)}
					</span>
				) : interviewReport?.cancelled ? (
					<Tooltip
						title="This interview has been cancelled. You can resume the interview or schedule it again."
						arrow
					>
						<Chip label="Interview Cancelled" className="font-semibold" />
					</Tooltip>
				) : interviewReport?.schedule ? (
					<Box
						className={`flex flex-row items-center h-full justify-center gap-1 relative group mr-5 ml-5`}
					>
						<Chip
							label={`Scheduled: ${getFormattedDateTime4(interviewReport?.schedule)}`}
							className="bg-blue-200 text-blue-800 font-semibold transition-all schedule-chip"
						/>
						<Tooltip title="Cancel Scheduled Interview" arrow>
							<Cancel
								className="ml-1 text-rose-500 absolute right-4 cancel-schedule-icon cursor-pointer"
								onClick={handleCancelSchedule}
							/>
						</Tooltip>
					</Box>
				) : interviewReport?.browserClosed ? (
					checkIf48HoursPassed(interviewReport?.lastActiveTimestamp) ? (
						<Tooltip
							title="Interview Closed Due to Inactivity: It has been more than 48 hours since the candidate closed the tab or browser."
							arrow
						>
							<Chip label="Dropped" className="bg-red-200 text-red-800 font-semibold" />
						</Tooltip>
					) : (
						<Tooltip title="Browser Closed: Candidate closed the tab or browser." arrow>
							<Chip label="Dropped" className="bg-red-200 text-red-800 font-semibold" />
						</Tooltip>
					)
				) : openingData?.proctoring ? (
					interviewReport?.meetingId ? (
						interviewReport?.isLatestMeetingIdInvalid ? (
							<Chip label="Previous Meeting Ended" />
						) : (
							<Button
								target="_blank"
								component={Link}
								to={`/admin/proctor/${interviewReport?._id}`}
								variant="text"
								color="primary"
								className="normal-case"
							>
								Join Meeting
							</Button>
						)
					) : interviewReport?.createdByAdmin ? (
						checkIf48HoursPassed(interviewReport?.createdAt) ? (
							<Tooltip
								title="It has been more than 48 hours since the candidate was added by admin. The candidate has not joined the interview yet."
								arrow
							>
								<Chip label="New" className="bg-red-200 text-red-800 font-semibold" />
							</Tooltip>
						) : (
							<Tooltip
								title="Candidate added by admin but has not joined the interview yet."
								arrow
							>
								<Chip label="New" className="bg-red-200 text-red-800 font-semibold" />
							</Tooltip>
						)
					) : (
						<Tooltip title="Candidate was unable to proceed." arrow>
							<Chip label="Dropped" className="bg-red-200 text-red-800 font-semibold" />
						</Tooltip>
					)
				) : interviewReport?.activeSession ? (
					<Tooltip title="Interview is ongoing but proctoring is disabled." arrow>
						<Chip label="Ongoing" />
					</Tooltip>
				) : interviewReport?.disconnected ? (
					checkIf48HoursPassed(interviewReport?.lastActiveTimestamp) ? (
						<Tooltip
							title="Interview Closed Due to Inactivity: It has been more than 48 hours since the candidate got disconnected. This could have happened due to network issues on the candidate's system or their browser crashing."
							arrow
						>
							<Chip label="Dropped" className="bg-red-200 text-red-800 font-semibold" />
						</Tooltip>
					) : (
						<Tooltip
							title="Candidate Disconnected: Candidate got disconnected. This can happen due to network issues on the candidate's system or their browser crashing."
							arrow
						>
							<Chip label="Dropped" className="bg-red-200 text-red-800 font-semibold" />
						</Tooltip>
					)
				) : interviewReport?.createdByAdmin ? (
					checkIf48HoursPassed(interviewReport?.createdAt) ? (
						<Tooltip
							title="It has been more than 48 hours since the candidate was added by admin. The candidate has not joined the interview yet."
							arrow
						>
							<Chip label="New" className="bg-red-200 text-red-800 font-semibold" />
						</Tooltip>
					) : (
						<Tooltip title="Candidate added by admin but has not joined the interview yet." arrow>
							<Chip label="New" className="bg-red-200 text-red-800 font-semibold" />
						</Tooltip>
					)
				) : (
					<Tooltip title="Candidate was unable to proceed." arrow>
						<Chip label="Dropped" className="bg-red-200 text-red-800 font-semibold" />
					</Tooltip>
				)}
			</div>
		</div>
	);
};

export default InterviewStatusRenderer;
