import axios from "./axiosInstance";

const createMeeting = async (_id, attendeeName, isRecordingEnabled) => {
	try {
		const response = await axios.post(`/api/aws/createMeeting`, {
			_id,
			attendeeName,
			isRecordingEnabled,
		});
		if (response.status === 200) {
			return response?.data;
		} else {
			return false;
		}
	} catch (e) {
		console.log("Something went wrong while creating meeting");
		console.log(e.message);
		const message = e?.response?.data?.message || e.message;
		throw new Error(message);
	}
};

const joinMeeting = async (meetingId, _id, attendeeName) => {
	try {
		const response = await axios.post(`/api/aws/joinMeeting`, {
			meetingId,
			_id,
			attendeeName,
		});
		if (response.status === 200) {
			return response?.data;
		} else {
			return false;
		}
	} catch (error) {
		console.log("error in joining meeting");
		console.log(error.message);
	}
};

const deleteAttendee = async (meetingId, attendeeId, _id) => {
	try {
		const response = await axios.post(`/api/aws/deleteAttendee`, {
			meetingId,
			attendeeId,
			_id,
		});
		if (response.status === 200) {
			return response?.data;
		} else {
			return false;
		}
	} catch (error) {
		console.log("error in deleting attendee");
		console.log(error.message);
	}
};

const endMeeting = async (meetingId, _id) => {
	try {
		const response = await axios.post(`/api/aws/endMeeting`, {
			meetingId,
			_id,
		});
		if (response.status === 200) {
			return response?.data;
		} else {
			return false;
		}
	} catch (error) {
		console.log("error in ending meeting");
		console.log(error.message);
	}
};

// const faceDetection = async (imgBlob, _id, timestamp) => {
// 	try {
// 		const formData = new FormData();
// 		formData.append("imgBlob", imgBlob);
// 		formData.append("_id", _id);
// 		formData.append("timestamp", timestamp);
// 		const response = await axios.post(`/api/aws/faceDetection`, formData, {
// 			headers: {
// 				"Content-Type": "multipart/form-data",
// 			},
// 		});
// 		if (response.status === 200) {
// 			return response?.data;
// 		} else {
// 			return false;
// 		}
// 	} catch (error) {
// 		console.log("error in joining meeting");
// 		console.log(error.message);
// 	}
// };

const getCapturedFrames = async (_id) => {
	try {
		const response = await axios.get(`/api/aws/getCapturedFrames?_id=${_id}`);
		if (response.status === 200) {
			return response?.data;
		} else {
			return false;
		}
	} catch (error) {
		console.log("error in getting captured frames");
		console.log(error.message);
	}
};

const textToSpeechGC = async (langCode, text, _id) => {
	try {
		const response = await axios.post(`/api/aws/textToSpeechGC`, {
			langCode,
			text,
			_id,
		});
		if (response.status === 200) {
			return response?.data;
		} else {
			return false;
		}
	} catch (error) {
		console.log("error in text to speech");
		console.log(error.message);
	}
};

export { createMeeting, joinMeeting, deleteAttendee, endMeeting, getCapturedFrames, textToSpeechGC };
