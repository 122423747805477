import React, { useState, useEffect, useRef } from "react";
import "./styles/ChatGPT.css";
import styled, { keyframes } from "styled-components";
import { isMobile } from "react-device-detect";
import {
	CircularProgress,
	Collapse,
	IconButton,
	TextareaAutosize,
	Button,
	Box,
	Typography,
	Stack,
	TextField,
	Tooltip,
	Snackbar,
	Link,
	LinearProgress,
} from "@mui/material";
import MicRecorder from "mic-recorder-to-mp3";
import { useParams, useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import MicIcon from "@mui/icons-material/Mic";
import CheckIcon from "@mui/icons-material/Check";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import SendIcon from "@mui/icons-material/Send";
import CloseIcon from "@mui/icons-material/Close";
import { LightAsync as SyntaxHighlighter } from "react-syntax-highlighter";
import { googlecode } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { handleUpload, handleUploadAnswers, unscriptedELSA } from "./Common";
import { Canvas } from "@react-three/fiber";
import { Environment, OrbitControls } from "@react-three/drei";
import { Avatar } from "./components/Avatar";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { stripMarkdown } from "./utilities/utilityFunctions";
import { textToSpeechGC } from "./utilities/awsApi";

const defaultMessages = {
	error_correction:
		"Here is a passage containing errors. Your task is to identify and correct these errors.",
	email_writing_assessment:
		"In this segment, you will be provided with a scenario, and your task is to compose an email based on that scenario.",
	listening_comprehension_test:
		"We will now proceed to the listening comprehension test. After you've heard the audio material, answer the following questions",
};

const defaultAnswerTimeLimitInMins = 7;
const defaultAnswerTimeLimitInSecs = defaultAnswerTimeLimitInMins * 60;

const noAutoSkipPhrases = [
	"code editor",
	"how was your interview experience",
	"how was your experience",
	"thoughts and suggestions for improvement",
	"please introduce yourself",
	"a brief overview of your background and experience",
];

const ChatDialog = ({
	loading,
	setLoading,
	recentQIndex,
	setRecentQIndex,
	currentQ,
	setCurrentQ,
	questionIndex,
	setQuestionIndex,
	text2SpeechStatus,
	setText2SpeechStatus,
	showIDE,
	setShowIDE,
	setCodeExample,
	codeExample,
	openingData,
	interviewReportData,
	interviewEnded,
	setInterviewEnded,
	avatarMode,
	setAvatarMode,
	audioState,
	setAudioState,
	lipSync,
	setLipSync,
	networkLost,
	setNetworkLost,
	ping,
	clientLogger,
	currentAudioDeviceId,
	orgName,
}) => {
	const isAutoSkippedRef = useRef(false);
	const [Mp3Recorder, setMp3Recorder] = useState(new MicRecorder({ bitRate: 128 }));
	const [editResponseOption, setEditResponseOption] = useState(false);
	const inputRef = useRef(null);
	const [showBlinker, setShowBlinker] = useState(false);
	const [editModeForMessage, setEditModeForMessage] = useState(false);
	const [latestUserMessage, setLatestUserMessage] = useState("");
	const [disableTapToSpeak, setDisableTapToSpeak] = useState(false);
	const [text, setText] = useState("");
	const [showContent, setShowContent] = useState(false);
	const [isBlocked, setIsBlocked] = useState(false);

	const [messages, setMessages] = useState([
		{
			role: "assistant",
			content: `Hi ${interviewReportData?.preferredName}, my name is Zi, and I will be your interviewer today. Welcome on behalf of ${orgName}, and thank you for applying for the role of ${openingData?.title}.\n\nThis interview, including your webcam and screen activities, will be recorded for a thorough and fair evaluation, and to help us improve our process.\n\nI’m here to make this experience comfortable and engaging. Let's get started!`,
		},
	]);
	const [isRecording, setIsRecording] = useState(false);
	const [scrollBottom, setBottom] = useState(false);
	const [isAssistantLoading, setIsAssistantLoading] = useState(false);
	const [windowSize, setWindowSize] = useState(getWindowSize());
	const [isChatBoxShown, setIsChatBoxShown] = useState(false);
	const [showTextToggle, setShowTextToggle] = useState(false);
	const [userInterviewReportId, setUserInterviewReportId] = useState(null);
	const [snackbarOpen, setSnackBarOpen] = useState(false);
	const [snackBarMessage, setSnackBarMessage] = useState("");
	const params = useParams();
	const navigate = useNavigate();
	const id = params.id;
	const [activeMessage, setActiveMessage] = useState(null);
	const [wavFile, setWavFile] = useState(null);
	const [Mp3FileForUnscriptedELSA, setMp3FileForUnscriptedELSA] = useState(null);
	const [timerContent, setTimerContent] = useState("00:00");
	const [timerInSeconds, setTimerInSeconds] = useState(0);
	const [isRecordingStoppedProgramatically, setIsRecordingStoppedProgramatically] = useState(false);
	const [timer, setTimer] = useState(null);
	const [showTimer, setShowTimer] = useState(false);
	const [responseLength, setResponseLength] = useState(null);
	const [reRecord, setReRecord] = useState(false);
	const [isClicking, setIsClicking] = useState(false);
	const [listeningComprehension, setListeningComprehension] = useState(false);
	const [audioScenario, setAudioScenario] = useState("");
	const [listQuestions, setListQuestions] = useState([]);
	const [listQuestionsCount, setListQuestionsCount] = useState(0);
	const [listQuestionsAnswered, setListQuestionsAnswered] = useState(0);
	const [showPlayButton, setShowPlayButton] = useState(true);
	const [writtenPrompt, setWrittenPrompt] = useState(false);
	const [errorCorrection, setErrorCorrection] = useState(false);
	const [paragraphWithError, setParagraphWithError] = useState("");
	const [emailAssessment, setEmailAssessment] = useState(false);
	const [composeEmailScenario, setComposeEmailScenario] = useState("");
	const [audioFiles, setAudioFiles] = useState([]);
	const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
	const [recordingStates, setRecordingStates] = useState([]);
	const [clickingStates, setClickingStates] = useState([]);
	const [reRecordStates, setReRecordStates] = useState([]);
	const [nonTechFunctionCallObject, setNonTechFunctionCallObject] = useState({
		listeningComprehension: false,
		errorCorrection: false,
		emailWriting: false,
	});
	const isFirstQRef = useRef(true);
	const NoAutoSkipRef = useRef(false);
	const [avatarRendered, setAvatarRendered] = useState(false);
	const autoSkipQTimeoutRef = useRef();
	const autoSkipQIntervalRef = useRef();
	const autoSkipQTimeoutS = openingData?.autoSkipQTimeoutS ?? 0;
	const autoSkipQTimeoutMs = autoSkipQTimeoutS * 1000;
	const [remainingSecondsForAutoSkipQ, setRemainingSecondsForAutoSkipQ] = useState(0);

	const clearAutoSkipTimers = () => {
		if (autoSkipQIntervalRef.current) {
			clearInterval(autoSkipQIntervalRef.current);
			autoSkipQIntervalRef.current = null;
		}
		if (autoSkipQTimeoutRef.current) {
			clearTimeout(autoSkipQTimeoutRef.current);
			autoSkipQTimeoutRef.current = null;
		}
	};

	const startAutoSkipTimers = async () => {
		setRemainingSecondsForAutoSkipQ(autoSkipQTimeoutS);

		autoSkipQIntervalRef.current = setInterval(() => {
			setRemainingSecondsForAutoSkipQ((prev) => prev - 1);
		}, 1000);

		autoSkipQTimeoutRef.current = setTimeout(async () => {
			setLoading(true);
			const noNetwork = await ping();
			if (noNetwork) return;

			if (audioState) {
				audioState.pause();
			}
			isAutoSkippedRef.current = true;
			// Use functional state update to ensure we get the latest messages state
			setMessages((prevMessages) => {
				const updatedMessages = [
					...prevMessages,
					{ role: "user", content: "Skip this question. Please move on to the next question." },
				];

				// Call handleSend with the updated messages
				handleSend("skip", updatedMessages);

				return updatedMessages; // Return the updated state to ensure it's applied
			});
			clearAutoSkipTimers();
		}, autoSkipQTimeoutMs);
	};

	let timeoutContainer = null;
	const stopRecordingButtonRef = useRef();

	useEffect(() => {
		currentAudioDeviceId &&
			setMp3Recorder(new MicRecorder({ bitRate: 128, deviceId: currentAudioDeviceId }));
	}, [currentAudioDeviceId]);

	useEffect(() => {
		return () => clearTimeout(timeoutContainer);
	}, []);

	const startTimer = () => {
		let totalSeconds = 0;
		setShowTimer(true);
		setResponseLength(null);
		setTimer(
			setInterval(() => {
				totalSeconds++;
				setTimerInSeconds(totalSeconds);
				const seconds = totalSeconds % 60;
				const minutes = Math.floor(totalSeconds / 60);
				setTimerContent(`${pad(minutes)}:${pad(seconds)}`);
				if (totalSeconds === defaultAnswerTimeLimitInSecs + 1) {
					setIsRecordingStoppedProgramatically(true);
					stopRecordingButtonRef.current?.click();
				}
			}, 1000),
		);
	};

	const stopTimer = () => {
		if (timer) {
			clearInterval(timer);
			setTimer(null);
		}
		setResponseLength(timerContent);
		setShowTimer(false);
		setTimerContent("00:00");
		setTimerInSeconds(0);
	};

	const pad = (number) => {
		return String(number).padStart(2, "0");
	};

	useEffect(() => {
		return () => {
			if (timer) {
				clearInterval(timer);
			}
		};
	}, [timer]);

	useEffect(() => {
		// console.log("2. As soon as interviewReportData is loaded and messages are set, this useEffect will be called");
		// this useEffect is useful when an interview is resumed and the recent message from ai is one of the function calls.
		if (messages.length > 2) {
			if (messages[messages.length - 1]?.role === "assistant") {
				setRecentQIndex(messages.length - 1);
				setQuestionIndex(messages.length - 1);
				const { function_call: function_call_response } = messages.at(-1);
				if (function_call_response) {
					errorCorrection && setErrorCorrection(false);
					emailAssessment && setEmailAssessment(false);
					listeningComprehension && setListeningComprehension(false);
					const { name: functionCallName, arguments: args } = function_call_response;
					const jsArgs = JSON.parse(args);
					if (functionCallName === "error_correction") {
						const { error_ridden_passage } = jsArgs;
						setParagraphWithError(error_ridden_passage);
						setErrorCorrection(true);
						setWrittenPrompt(true);
					} else if (functionCallName === "email_writing_assessment") {
						const { compose_email_scenario } = jsArgs;
						setComposeEmailScenario(compose_email_scenario);
						setEmailAssessment(true);
						setWrittenPrompt(true);
					} else if (functionCallName === "listening_comprehension_test") {
						const { questions_from_the_audio, audio_scenario } = jsArgs;
						const questionsFromAudio = questions_from_the_audio;
						const questionsList = questionsFromAudio.map((item) => item.question);
						setAudioScenario(audio_scenario);
						setListQuestions(questionsList);
						setListQuestionsCount(questionsList.length);
						setListeningComprehension(true);
					}
				}
				setCurrentQ(Math.trunc(messages.length / 2) + 1);
			} else if (messages[messages.length - 1]?.role === "user") {
				setCurrentQ(currentQ + 1);
			}
		}
		// console.log(`messages >>>>>> ${JSON.stringify(messages)}`);
	}, [messages]);

	useEffect(() => {
		// console.log("this is the final useEffect that should run each time the tile is changed based on questionIndex");
		// console.log(
		// 	`useEffect ran for questionIndex, right now it is>>>> ${questionIndex}`
		// );
		if (messages[questionIndex].content) {
			setActiveMessage(messages[questionIndex].content);
		} else {
			// console.log("the message active in here is ");
			// console.log(messages[questionIndex]);
			const functionCallName = messages[questionIndex]?.function_call?.name;
			const { name, arguments: args } = messages[questionIndex]?.function_call;

			const jsArgs = JSON.parse(args);
			if (functionCallName === "error_correction") {
				if (!paragraphWithError) {
					const { error_ridden_passage } = jsArgs;
					// console.log("current tile quesion is  error ridden passage ", error_ridden_passage);
					setActiveMessage(error_ridden_passage);
				} else {
					setActiveMessage(paragraphWithError);
				}
			} else if (functionCallName === "email_writing_assessment") {
				if (!composeEmailScenario) {
					const { compose_email_scenario } = jsArgs;
					// console.log("current tile quesion is email writing", compose_email_scenario);
					setActiveMessage(compose_email_scenario);
				} else {
					setActiveMessage(composeEmailScenario);
				}
			} else if (functionCallName === "listening_comprehension_test") {
				if (listQuestions.length === 0) {
					const { questions_from_the_audio, audio_scenario } = jsArgs;
					let finalText = "";
					questions_from_the_audio.forEach(({ question }, index) => {
						finalText += `Question ${question}\n`;
					});

					setActiveMessage(finalText);
				} else {
					// console.log(`inside listening_comprehension_test`);
					let finalText = "";
					listQuestions.forEach((question, index) => {
						finalText += `Question ${question}\n`;
					});
					setActiveMessage(finalText);
				}
			}
		}
		// console.log(
		// 	`activeMessge >>> message[${questionIndex}].content >>> ${messages[questionIndex]?.content}`
		// );
	}, [questionIndex]);

	useEffect(() => {
		if (!text2SpeechStatus) {
			if (audioState) {
				audioState.pause();
			}
		}
	}, [text2SpeechStatus]);

	useEffect(() => {
		const textInputBoxKey = window.localStorage.getItem("activate-textbox");
		if (textInputBoxKey === "ACTIVATE") {
			setShowTextToggle(true);
		}
	}, []);

	useEffect(() => {
		setUserInterviewReportId(interviewReportData?._id);
	}, [interviewReportData]);

	useEffect(() => {
		// console.log(`interviewReportData.messages &&
		// interviewReportData.messages.length: ${interviewReportData.messages} && ${interviewReportData.messages.length}`);
		if (interviewReportData.messages && interviewReportData.messages.length > 0) {
			if (interviewReportData.messages.length === 1) {
				isFirstQRef.current = true;
			} else isFirstQRef.current = false;
			const resumingMessages = interviewReportData.messages.map((message) => {
				const { role, content, function_call_response = null } = message;
				let function_call = null;
				if (function_call_response) {
					const { function_call: function_call_from_stored_messages } = function_call_response;
					if (function_call_from_stored_messages) {
						function_call = function_call_from_stored_messages;
					}
				}
				return {
					role: role,
					content: content,
					...(function_call && { function_call: function_call }),
				};
			});
			setMessages(resumingMessages);
			const latestFromResumingMessages = resumingMessages[resumingMessages.length - 1];
			if (latestFromResumingMessages.role === "assistant") {
				if (defaultMessages[latestFromResumingMessages?.function_call?.name]) {
					if (avatarMode) {
						setLoading(true);
						// rhubarb(
						// 	defaultMessages[
						// 		latestFromResumingMessages?.function_call?.name
						// 	]
						// );
						processTextForAudio(
							defaultMessages[latestFromResumingMessages?.function_call?.name],
							"avatar",
						);
					}
				} else if (latestFromResumingMessages.content) {
					if (avatarMode) {
						setLoading(true);
						// rhubarb(latestFromResumingMessages.content);
						processTextForAudio(latestFromResumingMessages.content, "avatar");
					}
				}
			}
		} else {
			isFirstQRef.current = true;
			if (text2SpeechStatus) {
				setLoading(true);
				// textToServer(messages[0].content);
				processTextForAudio(messages[0].content, "T2S");
			}
			if (avatarMode) {
				setLoading(true);
				// rhubarb(messages[0].content);
				processTextForAudio(messages[0].content, "avatar");
			}
		}
	}, [interviewReportData]);

	useEffect(() => {
		setEditResponseOption(openingData?.editResponses);
		if (openingData) {
			setNonTechFunctionCallObject((prev) => {
				return {
					...prev,
					listeningComprehension: openingData.listeningComprehension,
					errorCorrection: openingData.errorCorrection,
					emailWriting: openingData.emailWriting,
				};
			});
		}
	}, [openingData]);

	const messagesEndRef = useRef(null);
	const scrollToBottom = () => {
		messagesEndRef.current?.scrollIntoView({
			behavior: "smooth",
			block: "end",
		});
	};

	useEffect(() => {
		scrollToBottom();
	}, [messages, scrollBottom, editModeForMessage]);

	function getWindowSize() {
		const { innerWidth, innerHeight } = window;
		return { innerWidth, innerHeight };
	}

	useEffect(() => {
		function handleWindowResize() {
			setWindowSize(getWindowSize());
		}

		window.addEventListener("resize", handleWindowResize);

		return () => {
			window.removeEventListener("resize", handleWindowResize);
		};
	}, []);

	useEffect(() => {
		checkPermissions();
	}, []);

	const checkPermissions = () => {
		navigator.mediaDevices.getUserMedia(
			{ audio: true },
			() => {
				setIsBlocked(false);
			},
			() => {
				setIsBlocked(true);
			},
		);
	};

	const handleTextChange = (e) => {
		setText(e.target.value);
	};

	function codeAdder(content) {
		if (codeExample !== "") {
			const codeFences = "```";
			content += `
${codeFences}
${codeExample.trim()}
${codeFences}`;
		}
		return content;
	}

	const handleSend = (props, updatedMessages) => {
		const timeStarted = Date.now();
		setTimerInSeconds(0);
		setIsRecordingStoppedProgramatically(false);
		setShowBlinker(true);
		let content = "";
		if (props === "skip") {
			stopTimer();
			clientLogger(
				isAutoSkippedRef.current
					? "The question was auto skipped. Sending response to server for chat completion. No transcription step."
					: "User clicked skip the question. Sending response to server for chat completion. No transcription step.",
			);
			content = "Skip this question. Please move on to the next question.";
		} else if (props === "begin") {
			clientLogger(
				"User clicked let's begin. Sending response to server for chat completion. No transcription step.",
			);
			content = "Let's begin.";
		} else {
			content = text;
		}
		if (props !== "skip") {
			stopTimer();
			content = codeAdder(content);
		}
		let m = { role: "user", content: content };
		setLatestUserMessage(content);
		let temp = [...messages];
		setActiveMessage(null);
		setShowIDE(false);
		temp.push(m);
		// Use the updatedMessages passed in case of "skip" or default to current state
		const messagesToSend = updatedMessages || [...messages, { role: "user", content }];
		setMessages(messagesToSend); // Update the messages state
		setIsAssistantLoading(true);
		setWavFile(null);
		setResponseLength(null);
		setReRecord(false);
		handleUploadAnswers(
			messagesToSend,
			openingData?._id,
			userInterviewReportId,
			openingData?.isTechnical,
			nonTechFunctionCallObject,
			isAutoSkippedRef.current,
		)
			.then(async (res) => {
				isAutoSkippedRef.current = false;
				// !res.interviewEnded &&
				// 	text2SpeechStatus &&
				// 	textToServer(res.message);
				const timeEnded = Date.now();
				const timeDuration = timeEnded - timeStarted;
				clientLogger(
					`Response received from openai and rendering it on to the users screen. Time Taken - ${timeDuration}ms`,
					{
						timeDuration,
						responseReceivedFromOpenai: res,
					},
				);
				!res.interviewEnded && text2SpeechStatus && processTextForAudio(res.message, "T2S");
				const function_call = res?.openai_message?.function_call;
				let ms = {
					role: "assistant",
					content: res.message,
					function_call: function_call,
				};
				const functionCallName = function_call?.name;
				if (
					functionCallName === "error_correction" ||
					functionCallName === "email_writing_assessment"
				) {
					errorCorrection && setErrorCorrection(false);
					emailAssessment && setEmailAssessment(false);
					listeningComprehension && setListeningComprehension(false);
					if (functionCallName === "error_correction") {
						setErrorCorrection(true);
						const argumentsObj = JSON.parse(function_call.arguments);
						const errorRiddenPassage = argumentsObj.error_ridden_passage;
						setParagraphWithError(errorRiddenPassage);
						setWrittenPrompt(true);
					} else if (functionCallName === "email_writing_assessment") {
						setEmailAssessment(true);
						const argumentsObj = JSON.parse(function_call.arguments);
						const composeEmailScenario = argumentsObj.compose_email_scenario;
						setComposeEmailScenario(composeEmailScenario);
						setWrittenPrompt(true);
					}
				} else if (functionCallName === "listening_comprehension_test") {
					const argumentsObj = JSON.parse(function_call.arguments);
					const audioScenario = argumentsObj.audio_scenario;
					setAudioScenario(audioScenario);
					const questionsFromAudio = argumentsObj.questions_from_the_audio;
					const questionsList = questionsFromAudio.map((item) => item.question);
					setListQuestions(questionsList);
					setListQuestionsCount(questionsList.length);
					setListeningComprehension(true);
				} else {
					setWrittenPrompt(false);
					setErrorCorrection(false);
					setEmailAssessment(false);
					setListeningComprehension(false);
				}
				temp.push(ms);
				setMessages((prevMessages) => [...prevMessages, ms]);
				setIsAssistantLoading(false);
				setBottom(!scrollBottom);
				setText("");
				setCodeExample("");
				setIsRecording(false);
				if (isFirstQRef.current) isFirstQRef.current = false;
				if (!res.interviewEnded && avatarMode) {
					if (defaultMessages[functionCallName]) {
						// rhubarb(defaultMessages[functionCallName]);
						processTextForAudio(defaultMessages[functionCallName], "avatar");
						NoAutoSkipRef.current = noAutoSkipPhrases.some((phrase) =>
							defaultMessages[functionCallName].toLowerCase().includes(phrase),
						);
					} else {
						// rhubarb(res.message);
						processTextForAudio(res.message, "avatar");
						NoAutoSkipRef.current = noAutoSkipPhrases.some((phrase) =>
							res.message.toLowerCase().includes(phrase),
						);
					}
				} else {
					setLoading(false);
					NoAutoSkipRef.current = noAutoSkipPhrases.some((phrase) =>
						res?.message.toLowerCase().includes(phrase),
					);

					if (autoSkipQTimeoutS !== 0 && !NoAutoSkipRef.current && !text2SpeechStatus) {
						clearAutoSkipTimers();
						await startAutoSkipTimers();
					}
				}
				if (res.interviewEnded) {
					clearAutoSkipTimers();
					clientLogger("Interview Ended received from openai. Redirecting to /end route.");
					setAudioState(null);
					sessionStorage.removeItem("organization");
					setInterviewEnded(true);
					setAvatarMode(false);
					setText2SpeechStatus(false);
					setAudioState(null);
					setTimeout(() => {
						navigate("/end", {
							replace: true,
							state: {
								orgName: orgName,
							},
						});
					}, 5000);
				}
			})
			.catch((err) => {
				clientLogger("Error in handleuploadAnswers (chat completion)", {
					errorMessage: err?.response?.data?.message || err.message,
					level: "error",
				});
				console.log("Error:", err);
				setIsAssistantLoading(false);
				setBottom(!scrollBottom);
				setText("");
				setCodeExample("");
				setIsRecording(false);
				setLoading(false);
				setShowBlinker(false);
			});
	};

	// start recording
	const start = (questionIndex) => {
		if (isBlocked) {
			window.alert("permission denied");
		} else {
			if (!listeningComprehension) {
				wavFile && setWavFile(null);
				reRecord && setIsRecordingStoppedProgramatically(false);
				Mp3Recorder.start()
					.then(() => {
						startTimer();
						clientLogger(
							reRecord
								? "Re-recording started by candidate "
								: "Recording started by candidate",
						);
						setIsRecording(true);
					})
					.catch((e) => {
						clientLogger("Error while calling Mp3Recorder.start()", {
							level: "error",
							errorMessage: e.message,
						});
						console.error(e);
					});
			} else {
				setRecordingStates((prevStates) => {
					const newStates = [...prevStates];
					newStates[questionIndex] = true;
					return newStates;
				});
				Mp3Recorder.start()
					.then(() => {
						startTimer();
						setCurrentQuestionIndex(questionIndex);
					})
					.catch((e) => console.error(e));
			}
			return "started";
		}
	};

	const stop = () => {
		setLoading(true);
		Mp3Recorder.stop()
			.getMp3()
			.then(([buffer, blob]) => {
				clientLogger("Recording stopped by candidate.");
				if (!listeningComprehension) {
					const file = new File([blob], `inhand-${interviewReportData._id}.wav`);
					if (currentQ === 2) {
						setMp3FileForUnscriptedELSA(
							new File([blob], `inhand-${interviewReportData._id}.mp3`),
						);
					}
					setWavFile(file);
					setIsClicking(false);
					setLoading(false);
				} else {
					setRecordingStates((prevStates) => {
						const newStates = [...prevStates];
						newStates[currentQuestionIndex] = false;
						return newStates;
					});
					const file = new File(
						[blob],
						`response-${params.interviewReportId}-${currentQuestionIndex}.wav`,
					);
					setAudioFiles((prevFiles) => {
						const updatedFiles = [...prevFiles];
						const isNewRecording = !updatedFiles[currentQuestionIndex];
						updatedFiles[currentQuestionIndex] = file;
						if (isNewRecording) {
							setListQuestionsAnswered((prev) => prev + 1);
						}
						return updatedFiles;
					});
					setClickingStates((prevStates) => {
						const newStates = [...prevStates];
						newStates[currentQuestionIndex] = false;
						return newStates;
					});
					setLoading(false);
				}
			})
			.catch((e) => {
				console.log(`Error from stop: ${e}`);
				setLoading(false);
			});
	};

	const processAudio = () => {
		let speed = undefined;
		try {
			let connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
			speed = connection?.downlink ? `${connection?.downlink}Mb/s` : "";
		} catch (e) {
			console.log("Could not get downlink speed", e.message);
		}
		setTimerInSeconds(0);
		setIsRecordingStoppedProgramatically(false);
		const selectedLanguageISO639Code = openingData?.languageOfAnswers ?? "en";
		clientLogger(
			`Sending user recorded response file to whisper for transcription. Downlink speed - ${speed}`,
			{
				selectedLanguageISO639Code: selectedLanguageISO639Code,
				downlinkSpeed: `${speed}Mb/s`,
			},
		);
		const timeStarted = Date.now();
		currentQ === 2 &&
			unscriptedELSA(Mp3FileForUnscriptedELSA, userInterviewReportId)
				.then((res) => {
					clientLogger("unscriptedELSA call successful.");
					// console.log("response from unscriptedELSA", res);
					// console.log(`response from unscriptedELSA in JSON: ${JSON.stringify(res)}`);
				})
				.catch((e) => {
					console.log("error from unscriptedELSA", e);
				});
		handleUpload(wavFile, selectedLanguageISO639Code, userInterviewReportId)
			.then((res) => {
				let content = res.data.message;
				const timeEnded = Date.now();
				const timeDuration = timeEnded - timeStarted;
				clientLogger(`Client Received transcription. Time Duration - ${timeDuration}ms`, {
					transcription: content,
				});
				content = codeAdder(content);
				let m = { role: "user", content: content };
				setActiveMessage(null);
				setShowIDE(false);
				setLatestUserMessage(content);
				let temp = [...messages];
				temp.push(m);
				setMessages((prevMessages) => [...prevMessages, m]);
				setShowBlinker(true);
				if (editResponseOption) {
					setLoading(false);
					setIsRecording(false);
					setDisableTapToSpeak(true);
					return;
				}
				setIsAssistantLoading(true);
				setWavFile(null);
				setResponseLength(null);
				setReRecord(false);
				clientLogger(
					"Client received trancription, sending candidate's answer to openai chat completion.",
					{
						methodCalled: "handleUploadAnswers",
					},
				);
				handleUploadAnswers(
					temp,
					openingData?._id,
					userInterviewReportId,
					openingData?.isTechnical,
					nonTechFunctionCallObject,
				)
					.then(async (res) => {
						// !res.interviewEnded &&
						// 	text2SpeechStatus &&
						// 	textToServer(res.message);
						const timeEnded = Date.now();
						const timeDuration = timeEnded - timeStarted;
						clientLogger(
							`Response received from openai and rendering it on to the users screen. Time Taken - ${timeDuration}ms`,
							{
								timeDuration,
								responseReceivedFromOpenai: res,
							},
						);
						!res.interviewEnded && text2SpeechStatus && processTextForAudio(res.message, "T2S");
						const function_call = res?.openai_message?.function_call;
						let ms = {
							role: "assistant",
							content: res.message,
							function_call: function_call,
						};
						const functionCallName = function_call?.name;
						if (
							functionCallName === "error_correction" ||
							functionCallName === "email_writing_assessment"
						) {
							errorCorrection && setErrorCorrection(false);
							emailAssessment && setEmailAssessment(false);
							listeningComprehension && setListeningComprehension(false);
							if (functionCallName === "error_correction") {
								setErrorCorrection(true);
								const argumentsObj = JSON.parse(function_call.arguments);
								const errorRiddenPassage = argumentsObj.error_ridden_passage;
								setParagraphWithError(errorRiddenPassage);
								setWrittenPrompt(true);
							} else if (functionCallName === "email_writing_assessment") {
								setEmailAssessment(true);
								const argumentsObj = JSON.parse(function_call.arguments);
								const composeEmailScenario = argumentsObj.compose_email_scenario;
								setComposeEmailScenario(composeEmailScenario);
								setWrittenPrompt(true);
							}
						} else if (functionCallName === "listening_comprehension_test") {
							const argumentsObj = JSON.parse(function_call.arguments);
							const audioScenario = argumentsObj.audio_scenario;
							setAudioScenario(audioScenario);
							const questionsFromAudio = argumentsObj.questions_from_the_audio;
							const questionsList = questionsFromAudio.map((item) => item.question);
							setListQuestions(questionsList);
							setListQuestionsCount(questionsList.length);
							setListeningComprehension(true);
						} else {
							setWrittenPrompt(false);
							setErrorCorrection(false);
							setEmailAssessment(false);
							setListeningComprehension(false);
						}
						temp.push(ms);
						setMessages((prevMessages) => [...prevMessages, ms]);
						setIsAssistantLoading(false);
						setBottom(!scrollBottom);
						setIsRecording(false);
						setCodeExample("");
						if (isFirstQRef.current) isFirstQRef.current = false;
						if (!res.interviewEnded && avatarMode) {
							if (defaultMessages[functionCallName]) {
								// rhubarb(defaultMessages[functionCallName]);
								processTextForAudio(defaultMessages[functionCallName], "avatar");
								NoAutoSkipRef.current = noAutoSkipPhrases.some((phrase) =>
									defaultMessages[functionCallName].toLowerCase().includes(phrase),
								);
							} else {
								// rhubarb(res.message);
								processTextForAudio(res.message, "avatar");
								NoAutoSkipRef.current = noAutoSkipPhrases.some((phrase) =>
									res.message.toLowerCase().includes(phrase),
								);
							}
						} else {
							setLoading(false);
							NoAutoSkipRef.current = noAutoSkipPhrases.some((phrase) =>
								res?.message.toLowerCase().includes(phrase),
							);
							if (autoSkipQTimeoutS !== 0 && !NoAutoSkipRef.current && !text2SpeechStatus) {
								clearAutoSkipTimers();
								await startAutoSkipTimers();
							}
						}
						if (res.interviewEnded) {
							clearAutoSkipTimers();
							clientLogger(
								"Interview Ended received from openai on the client. Redirecting to /end route.",
								{
									interviewEnded: true,
								},
							);
							sessionStorage.removeItem("organization");
							setInterviewEnded(true);
							setAvatarMode(false);
							setText2SpeechStatus(false);
							setAudioState(null);
							setTimeout(() => {
								navigate("/end", {
									replace: true,
									state: {
										orgName: orgName,
									},
								});
							}, 5000);
						}
					})
					.catch((e) => {
						clientLogger("Error in handleuploadAnswers (chat completion)", {
							errorMessage: e?.response?.data?.message || e.message,
							level: "error",
						});
						console.log(e);
						setCodeExample("");
						setIsAssistantLoading(false);
						setBottom(!scrollBottom);
						setIsRecording(false);
						setLoading(false);
						setShowBlinker(false);
					});
			})
			.catch((e) => {
				clientLogger("Error in handleUpload (transcription)", {
					level: "error",
					errorMessage: e?.response?.data?.message || e.message,
				});
				console.log(e);
				setCodeExample("");
				setIsAssistantLoading(false);
				setBottom(!scrollBottom);
				setIsRecording(false);
				setLoading(false);
				setShowBlinker(false);
			});
	};

	const processAudioForListeningComprehension = () => {
		// console.log(
		// 	'audioFiles names:',
		// 	audioFiles.map((file) => file.name)
		// );
		// const playAudioSeq = (files, index = 0) => {
		// 	if (index < files.length) {
		// 		const file = files[index];
		// 		const audioUrl = URL.createObjectURL(file);
		// 		const audio = new Audio(audioUrl);
		// 		audio.play();
		// 		audio.onended = () => {
		// 			URL.revokeObjectURL(audioUrl);
		// 			playAudioSeq(files, index + 1);
		// 		};
		// 	}
		// };
		// playAudioSeq(audioFiles);
		// return null;

		let finalTranscription = "";
		const selectedLanguageISO639Code = openingData?.languageOfAnswers ?? "en";
		Promise.all(
			audioFiles.map((file, index) =>
				handleUpload(file, selectedLanguageISO639Code, userInterviewReportId)
					.then((res) => `Answer-${index + 1}. ${res.data.message}\n`)
					.catch((error) => {
						console.error(`Error while transcribing file ${index + 1}:`, error);
						return `Error in transcribing file ${index + 1}`;
					}),
			),
		)
			.then((transcriptions) => {
				finalTranscription = transcriptions.join("\n");
				let content = finalTranscription;
				content = codeAdder(content);
				let m = { role: "user", content: content };
				setActiveMessage(null);
				setShowIDE(false);
				setLatestUserMessage(content);
				let temp = [...messages];
				temp.push(m);
				setMessages((prevMessages) => [...prevMessages, m]);
				setShowBlinker(true);
				if (editResponseOption) {
					setLoading(false);
					setRecordingStates((prevStates) => {
						const newStates = [...prevStates];
						newStates.forEach((state, index) => {
							newStates[index] = false;
						});
						return newStates;
					});
					setIsRecording(false);
					setDisableTapToSpeak(true);
					return;
				}
				setIsAssistantLoading(true);
				wavFile && setWavFile(null);
				setResponseLength(null);
				setReRecordStates((prevStates) => {
					const newStates = [...prevStates];
					newStates.forEach((state, index) => {
						newStates[index] = false;
					});
					return newStates;
				});
				setReRecord(false);
				handleUploadAnswers(
					temp,
					openingData?._id,
					userInterviewReportId,
					openingData?.isTechnical,
					nonTechFunctionCallObject,
				)
					.then(async (res) => {
						// !res.interviewEnded &&
						// 	text2SpeechStatus &&
						// 	textToServer(res.message);
						!res.interviewEnded && text2SpeechStatus && processTextForAudio(res.message, "T2S");
						const function_call = res?.openai_message?.function_call;
						let ms = {
							role: "assistant",
							content: res.message,
							function_call: function_call,
						};
						const functionCallName = function_call?.name;
						if (
							functionCallName === "error_correction" ||
							functionCallName === "email_writing_assessment"
						) {
							errorCorrection && setErrorCorrection(false);
							emailAssessment && setEmailAssessment(false);
							listeningComprehension && setListeningComprehension(false);
							if (functionCallName === "error_correction") {
								setErrorCorrection(true);
								const argumentsObj = JSON.parse(function_call.arguments);
								const errorRiddenPassage = argumentsObj.error_ridden_passage;
								setParagraphWithError(errorRiddenPassage);
								setWrittenPrompt(true);
							} else if (functionCallName === "email_writing_assessment") {
								setEmailAssessment(true);
								const argumentsObj = JSON.parse(function_call.arguments);
								const composeEmailScenario = argumentsObj.compose_email_scenario;
								setComposeEmailScenario(composeEmailScenario);
								setWrittenPrompt(true);
							}
						} else if (functionCallName === "listening_comprehension_test") {
							const argumentsObj = JSON.parse(function_call.arguments);
							const audioScenario = argumentsObj.audio_scenario;
							setAudioScenario(audioScenario);
							const questionsFromAudio = argumentsObj.questions_from_the_audio;
							const questionsList = questionsFromAudio.map((item) => item.question);
							setListQuestions(questionsList);
							setListQuestionsCount(questionsList.length);
							setListeningComprehension(true);
						} else {
							setWrittenPrompt(false);
							setErrorCorrection(false);
							setEmailAssessment(false);
							setListeningComprehension(false);
						}

						temp.push(ms);
						setMessages((prevMessages) => [...prevMessages, ms]);
						setIsAssistantLoading(false);
						setBottom(!scrollBottom);
						setIsRecording(false);
						setCodeExample("");
						if (isFirstQRef.current) isFirstQRef.current = false;
						if (!res.interviewEnded && avatarMode) {
							if (defaultMessages[functionCallName]) {
								// rhubarb(defaultMessages[functionCallName]);
								processTextForAudio(defaultMessages[functionCallName], "avatar");
								NoAutoSkipRef.current = noAutoSkipPhrases.some((phrase) =>
									defaultMessages[functionCallName].toLowerCase().includes(phrase),
								);
							}
						} else {
							setLoading(false);
							NoAutoSkipRef.current = noAutoSkipPhrases.some((phrase) =>
								res?.message.toLowerCase().includes(phrase),
							);
							if (autoSkipQTimeoutS !== 0 && !NoAutoSkipRef.current && !text2SpeechStatus) {
								clearAutoSkipTimers();
								await startAutoSkipTimers();
							}
						}
						if (res.interviewEnded) {
							clearAutoSkipTimers();
							sessionStorage.removeItem("organization");
							setInterviewEnded(true);
							setAvatarMode(false);
							setText2SpeechStatus(false);
							setAudioState(null);
							setTimeout(() => {
								navigate("/end", {
									replace: true,
									state: {
										orgName: orgName,
									},
								});
							}, 5000);
						}
					})
					.catch((e) => {
						console.log(e);
						setCodeExample("");
						setIsAssistantLoading(false);
						setBottom(!scrollBottom);
						setIsRecording(false);
						setLoading(false);
						setShowBlinker(false);
					});
			})
			.catch((error) => {
				console.error("Error in processing audio files:", error);
				setLoading(false);
			});
	};

	const handleSaveLatestMessage = () => {
		const temp = [...messages];
		let m = { role: "user", content: latestUserMessage };
		setActiveMessage(null);
		setShowIDE(false);
		temp[temp.length - 1] = m;
		setMessages((prevMessages) => {
			let newMessages = [...prevMessages];
			newMessages[newMessages.length - 1] = m;
			return newMessages;
		});
	};

	const redoLastMessages = () => {
		setLatestUserMessage("");
		setCodeExample("");
		setIsAssistantLoading(false);
		setBottom(!scrollBottom);
		setIsRecording(false);
		setLoading(false);
		setShowBlinker(false);
		setEditModeForMessage(false);
		setDisableTapToSpeak(false);
		setMessages((prev) => {
			return prev.slice(0, -1);
		});
	};

	const sendUpdatedMessagesForCompletion = async () => {
		try {
			if (latestUserMessage.trim() == "") {
				setSnackBarMessage("User response can't be empty");
				setSnackBarOpen(true);
				return;
			}
			setDisableTapToSpeak(false);
			setShowBlinker(true);
			setLoading(true);
			setEditModeForMessage(false);
			const temp = [...messages];
			let m = { role: "user", content: latestUserMessage };
			temp[temp.length - 1] = m;
			setMessages((prevMessages) => {
				let newMessages = [...prevMessages];
				newMessages[newMessages.length - 1] = m;
				return newMessages;
			});
			setActiveMessage(null);
			setShowIDE(false);
			setIsAssistantLoading(true);
			const res = await handleUploadAnswers(
				temp,
				openingData?._id,
				userInterviewReportId,
				openingData?.isTechnical,
				nonTechFunctionCallObject,
			);
			// !res.interviewEnded &&
			// 	text2SpeechStatus &&
			// 	textToServer(res.message);
			!res.interviewEnded && text2SpeechStatus && processTextForAudio(res.message, "T2S");
			!res.interviewEnded && avatarMode && processTextForAudio(res.message, "avatar");
			const function_call = res?.openai_message?.function_call;
			let ms = {
				role: "assistant",
				content: res.message,
				function_call: function_call,
			};
			const functionCallName = function_call?.name;
			if (functionCallName === "error_correction" || functionCallName === "email_writing_assessment") {
				errorCorrection && setErrorCorrection(false);
				emailAssessment && setEmailAssessment(false);
				listeningComprehension && setListeningComprehension(false);
				if (functionCallName === "error_correction") {
					setErrorCorrection(true);
					const argumentsObj = JSON.parse(function_call.arguments);
					const errorRiddenPassage = argumentsObj.error_ridden_passage;
					setParagraphWithError(errorRiddenPassage);
					setWrittenPrompt(true);
				} else if (functionCallName === "email_writing_assessment") {
					setEmailAssessment(true);
					const argumentsObj = JSON.parse(function_call.arguments);
					const composeEmailScenario = argumentsObj.compose_email_scenario;
					setComposeEmailScenario(composeEmailScenario);
					setWrittenPrompt(true);
				}
			} else if (functionCallName === "listening_comprehension_test") {
				const argumentsObj = JSON.parse(function_call.arguments);
				const audioScenario = argumentsObj.audio_scenario;
				setAudioScenario(audioScenario);
				const questionsFromAudio = argumentsObj.questions_from_the_audio;
				const questionsList = questionsFromAudio.map((item) => item.question);
				setListQuestions(questionsList);
				setListQuestionsCount(questionsList.length);
				setListeningComprehension(true);
			} else {
				setWrittenPrompt(false);
				setErrorCorrection(false);
				setEmailAssessment(false);
				setListeningComprehension(false);
			}
			temp.push(ms);
			setMessages((prevMessages) => [...prevMessages, ms]);
			if (res.interviewEnded) {
				sessionStorage.removeItem("organization");
				setInterviewEnded(true);
				setAvatarMode(false);
				setText2SpeechStatus(false);
				setAudioState(null);
				setTimeout(() => {
					navigate("/end", {
						replace: true,
						state: {
							orgName: orgName,
						},
					});
				}, 5000);
			}
		} catch (e) {
			console.log(e);
		} finally {
			setCodeExample("");
			setIsAssistantLoading(false);
			setBottom(!scrollBottom);
			setIsRecording(false);
			setShowBlinker(false);
			if (isFirstQRef.current) isFirstQRef.current = false;
			setLoading(false);
		}
	};

	const handleToggleChatBox = (e) => {
		e.stopPropagation();
		setIsChatBoxShown((prev) => !prev);
	};

	const ChatBoxToggleButton = ({ type = "open" }) => {
		const openStyleObj = {
			position: "absolute",
			marginTop: "5px",
			marginLeft: "5px",
			left: 0,
			top: 0,
			backgroundColor: "#fff",
			borderRadius: "50%",
		};
		const closeStyleObj = {
			position: "absolute",
			marginTop: "5px",
			marginLeft: "5px",
			top: 0,
			left: 0,
			backgroundColor: "#fff",
			borderRadius: "50%",
		};
		const styleOptions = {
			open: openStyleObj,
			close: closeStyleObj,
		};
		const styleObj = styleOptions[type];
		return (
			<div style={styleObj}>
				<IconButton onClick={handleToggleChatBox}>
					{type == "open" ? <ChevronRightIcon /> : <ChevronLeftIcon />}
				</IconButton>
			</div>
		);
	};

	// const wavesurfer = (toDo) => {
	// 	console.log(`>>>>> inside wavesurfer with toDo: ${toDo}`);
	// 	let deviceId = '';
	// 	const waveform = document.getElementById('waveform');
	// 	waveform.style.display = 'inline-block';
	// 	const wavesurfer = WaveSurfer.create({
	// 		container: '#waveform',
	// 		height: 70,
	// 		waveColor: 'rgb(200, 0, 200)',
	// 		progressColor: 'rgb(100, 0, 100)',
	// 		barWidth: 2,
	// 		barGap: 1,
	// 		barRadius: 2,
	// 	});
	// 	const record = wavesurfer.registerPlugin(RecordPlugin.create());
	// 	RecordPlugin.getAvailableAudioDevices().then((devices) => {
	// 		deviceId = devices[0].deviceId;
	// 	});
	// 	toDo === 'start'
	// 		? record.startRecording({ deviceId })
	// 		: record.stopRecording();
	// };

	const renderChatInput = () => {
		const handleButtonOnClick = () => {
			if (loading || interviewEnded || showTextToggle) {
				return;
			} else {
				if (isRecording) {
					stop();
				} else {
					start();
				}
			}
		};

		if (disableTapToSpeak) {
			return <></>;
		}
		return (
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "space-around",
					height: showIDE ? "auto" : "100%",
				}}
			>
				{!interviewEnded &&
					showTextToggle &&
					(isChatBoxShown ? (
						<>
							<div
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
								}}
							>
								<ChatBoxToggleButton type={"close"} />
								<ChatInput
									minRows={1}
									maxRows={4}
									autoFocus
									ref={inputRef}
									placeholder="e.g. Write a message to submit"
									value={text}
									disabled={loading || isRecording}
									onChange={handleTextChange}
									className="boom"
								/>
							</div>
						</>
					) : (
						<>
							<ChatBoxToggleButton type="open" />
						</>
					))}
				{recentQIndex === questionIndex && latestUserMessage && (
					<div className="flex items-center justify-end space-x-2 mb-4 mt-4 w-full">
						<div className="bg-gray-100 rounded-lg p-3 max-h-14 overflow-auto">
							<p className="text-sm text-gray-600">
								<FormattedMessage message={latestUserMessage} />
							</p>
						</div>
						<div className="bg-gray-200 rounded-full p-2 flex items-center justify-center">
							<i className="fas fa-user text-gray-600"></i>
						</div>
					</div>
				)}
				{/* (writtenPrompt || listeningComprehension) && */}
				{avatarMode && recentQIndex === questionIndex && (
					<Box className="relative mb-4 w-full h-full">
						<Canvas
							className=""
							shadows
							camera={{
								position: [0, 0, 8],
								fov: 32,
							}}
						>
							<OrbitControls enablePan={false} enableRotate={false} enableZoom={false} />
							<Avatar
								position={[0, -3.2, 6]}
								scale={2}
								audioState={audioState}
								avatarRendered={avatarRendered}
								setAvatarRendered={setAvatarRendered}
								loading={loading}
								clientLogger={clientLogger}
							/>
							<Environment files="/img/venice_sunset_1k.hdr" />
						</Canvas>
						{avatarRendered && (
							<Button
								disabled={!audioState}
								id="muteBtn"
								className={`
							absolute text-xs absolute flex items-center bg-gray-200 p-2 rounded focus:outline-none ${audioState && "text-gray-900"} normal-case top-[90%] ${isMobile ? "right-0" : "right-[22%]"} rounded focus:outline-none focus:border-none transform -translate-y-1/2`}
								onClick={async () => {
									if (audioState) {
										audioState.pause();
										if (
											autoSkipQTimeoutS !== 0 &&
											!isFirstQRef.current &&
											!NoAutoSkipRef.current
										) {
											// alert(
											// 	`autoSkipQTimeoutS: ${autoSkipQTimeoutS}, isFirstQRef.current: ${isFirstQRef.current}, NoAutoSkip: ${NoAutoSkip}`,
											// );
											clearAutoSkipTimers();
											startAutoSkipTimers();
										}
										// console.log(`audioState paused by clicking mute for avatar`);
										clientLogger(`audioState paused by clicking mute for avatar`);
									}
								}}
								disableRipple
							>
								{/* <i class="fas fa-volume-up"></i> */}
								{audioState ? (
									<i className="fas fa-volume-up"></i>
								) : (
									<i className="fas fa-volume-mute text-gray-500"></i>
								)}
								<span className={`ml-2 ${!audioState && "text-gray-500"}`}>Mute</span>
							</Button>
						)}
					</Box>
				)}

				{!loading && recentQIndex === questionIndex ? (
					writtenPrompt || listeningComprehension ? (
						writtenPrompt ? (
							errorCorrection ? (
								<Box className="mb-6 text-gray-900 p-6">
									<p className="mb-6 font-bold">Here's a paragraph with several errors:</p>

									<p className="mb-6">
										{paragraphWithError.split("\n").map((line, index) => (
											<React.Fragment key={index}>
												{line} <br />
											</React.Fragment>
										))}
									</p>
									<label
										for="answer"
										className="block text-sm font-medium text-gray-700 mb-2"
									>
										Correct the errors:
									</label>
									{/* <textarea
										id='answer'
										name='answer'
										rows='4'
										className='w-full p-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300'
										onChange={handleTextChange}></textarea> */}
									<ChatInput
										spellCheck={false}
										writtenPrompt={writtenPrompt}
										id="answer"
										name="answer"
										minRows={6}
										maxRows={7}
										ref={inputRef}
										value={text}
										disabled={loading || isRecording}
										onChange={handleTextChange}
										className="boom w-full p-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
									/>
								</Box>
							) : (
								emailAssessment && (
									<Box className="mb-6 text-gray-900 p-6">
										<p className="mb-6 font-bold">Here's a email scenario:</p>

										<p className="mb-6">
											{composeEmailScenario.split("\n").map((line, index) => (
												<React.Fragment key={index}>
													{line} <br />
												</React.Fragment>
											))}
										</p>
										<label
											for="answer"
											className="block text-sm font-medium text-gray-700 mb-2"
										>
											Write the email:
										</label>
										{/* <textarea
											id='answer'
											name='answer'
											rows='4'
											className='w-full p-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300'
											onChange={
												handleTextChange
											}></textarea> */}
										<ChatInput
											spellCheck={false}
											writtenPrompt={writtenPrompt}
											id="answer"
											name="answer"
											minRows={6}
											maxRows={7}
											ref={inputRef}
											value={text}
											disabled={loading || isRecording}
											onChange={handleTextChange}
											className="boom w-full p-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
										/>
									</Box>
								)
							)
						) : (
							listeningComprehension && (
								<Box className="mb-6 text-gray-900 p-6">
									<p className="mb-6 flex items-center space-x-2">
										<i className="fas fa-headphones"></i>
										<span>Listen to the scenario:</span>
									</p>
									<div className="bg-gray-200 p-4 rounded-md mb-6 flex items-center justify-center">
										<i
											className={`fas fa-play cursor-pointer ${
												!showPlayButton
													? "text-gray-400 cursor-wait"
													: !audioScenario && "text-gray-400 cursor-wait"
											}`}
											onClick={() => {
												if (showPlayButton && audioScenario) {
													setShowPlayButton(false);
													// textToServer(audioScenario);
													processTextForAudio(audioScenario, "T2S");
												}
											}}
										></i>
									</div>
									<div className="mb-6">
										{listQuestions.map((question, index) => (
											<div>
												<p>
													<strong>Question:</strong> {question}
												</p>
												{!loading && (
													<div className="flex items-center space-x-2 py-4">
														<>
															<Typography
																variant="span"
																className="text-xs"
																sx={
																	(index === 1 && !reRecordStates[0]) ||
																	index > 1 ||
																	reRecordStates[index] ||
																	currentQ > 3 ||
																	audioState ||
																	clickingStates[index] ||
																	recordingStates[index] ||
																	recordingStates.some(
																		(state, idx) =>
																			state && idx !== index,
																	) ||
																	(openingData?.isCodeEditorRequired &&
																		codeExample !== "")
																		? {
																				display: "none",
																			}
																		: {
																				animation:
																					"flash 2s infinite",

																				"@keyframes flash": {
																					"0%, 100%": {
																						borderColor:
																							"transparent",
																						color: "transparent",
																					},
																					"50%": {
																						borderColor:
																							"darkgray",
																						color: "black",
																					},
																				},
																			}
																}
															>
																Click here to answer
															</Typography>
															<Box
																sx={
																	(index === 1 && !reRecordStates[0]) ||
																	index > 1 ||
																	reRecordStates[index] ||
																	currentQ > 3 ||
																	audioState ||
																	clickingStates[index] ||
																	recordingStates[index] ||
																	recordingStates.some(
																		(state, idx) =>
																			state && idx !== index,
																	) ||
																	(openingData?.isCodeEditorRequired &&
																		codeExample !== "")
																		? {}
																		: {
																				display: "inline-block",
																				padding: "0.25rem",
																				border: "4px solid transparent",
																				borderRadius: "0.375rem",
																				animation:
																					"flash 2s infinite",
																				marginTop: "0.25rem",
																				"@keyframes flash": {
																					"0%, 100%": {
																						borderColor:
																							"transparent",
																						color: "transparent",
																					},
																					"50%": {
																						borderColor:
																							"darkgray",
																						color: "black",
																					},
																				},
																			}
																}
															>
																<Button
																	disabled={
																		audioState ||
																		clickingStates[index] ||
																		recordingStates[index] ||
																		recordingStates.some(
																			(state, idx) =>
																				state && idx !== index,
																		)
																	}
																	type="submit"
																	color="primary"
																	onClick={() => {
																		start(index);
																		clearAutoSkipTimers();
																		setRemainingSecondsForAutoSkipQ(0);
																	}}
																	className={`normal-case text-xs bg-red-500 text-white py-2 px-3 rounded hover:bg-red-600 focus:outline-none focus:border-none ${
																		audioState ||
																		recordingStates[index] ||
																		recordingStates.some(
																			(state, idx) =>
																				state && idx !== index,
																		)
																			? "cursor-not-allowed opacity-50"
																			: ""
																	}`}
																>
																	{audioState ? (
																		<Box>
																			<i className="fas fa-microphone mr-1"></i>
																			Wait for audio to finish
																		</Box>
																	) : (
																		<Box>
																			<Box
																				id="blinkingMic"
																				style={{
																					visibility:
																						recordingStates[index]
																							? "visible"
																							: "hidden",
																				}}
																				className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex items-center justify-center gap-1  ${
																					!showTimer && "hidden"
																				}`}
																			>
																				<i className="fas fa-microphone  animate-pulse"></i>
																				<Box
																					id="timerDisplay"
																					className=" text-sm"
																				>
																					{timerContent}
																				</Box>
																			</Box>
																			<Box
																				style={{
																					visibility:
																						recordingStates[index]
																							? "hidden"
																							: "visible",
																				}}
																			>
																				<i className="fas fa-microphone mr-1"></i>
																				{reRecordStates[index]
																					? "Re-record"
																					: "Record Response"}
																			</Box>
																		</Box>
																	)}
																</Button>
															</Box>
															<Button
																disabled={
																	clickingStates[index] ||
																	!recordingStates[index]
																}
																type="submit"
																color="primary"
																onClick={() => {
																	setClickingStates((prevStates) => {
																		const newStates = [...prevStates];
																		newStates[index] = true;
																		return newStates;
																	});
																	stopTimer();
																	setReRecordStates((prevStates) => {
																		const newStates = [...prevStates];
																		newStates[index] = true;
																		return newStates;
																	});
																	timeoutContainer = setTimeout(() => {
																		stop();
																	}, 1000);
																}}
																className={`normal-case text-xs bg-red-500 text-white py-2 px-3 rounded hover:bg-red-600 focus:outline-none focus:border-none relative ${
																	!recordingStates[index] &&
																	"cursor-not-allowed opacity-50"
																}`}
															>
																<Box
																	style={{
																		visibility: clickingStates[index]
																			? "hidden"
																			: "visible",
																	}}
																>
																	<i className="fas fa-stop-circle mr-1"></i>
																	Stop Recording
																</Box>
																<Box
																	className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
																	style={{
																		visibility: clickingStates[index]
																			? "visible"
																			: "hidden",
																	}}
																>
																	<i className="fas fa-circle-notch fa-spin mr-1"></i>
																</Box>
															</Button>
														</>
													</div>
												)}
											</div>
										))}
									</div>{" "}
								</Box>
							)
						)
					) : (
						<div className="text-center mb-4 text-gray-900  font-bold text-xl ai-response-renderer md-table">
							{!loading && <Markdown remarkPlugins={[remarkGfm]}>{activeMessage}</Markdown>}
						</div>
					)
				) : (
					!loading && (
						<div className="text-center mb-0 text-gray-900 font-bold text-xl ai-response-renderer md-table">
							<Markdown remarkPlugins={[remarkGfm]}>{activeMessage}</Markdown>
						</div>
					)
				)}
				{recentQIndex !== questionIndex && (
					<div className="flex items-center justify-end space-x-2 mt-4 w-full">
						<div className="bg-gray-100 rounded-lg p-3 max-h-20 overflow-auto">
							<p className="text-xs text-gray-600">
								<FormattedMessage message={messages[questionIndex + 1]?.content} />
							</p>
						</div>
						<div className="bg-gray-200 rounded-full p-2 flex items-center justify-center">
							<i class="fas fa-user text-gray-600"></i>
						</div>
					</div>
				)}
				{!loading &&
					!isFirstQRef.current &&
					!NoAutoSkipRef.current &&
					autoSkipQTimeoutS > 0 &&
					remainingSecondsForAutoSkipQ > 0 && (
						<Box className="relative m-6 w-4/5">
							{/* Progress bar */}
							<LinearProgress
								className="bg-gray-200 w-full h-2 rounded-full"
								classes={{ barColorPrimary: "bg-green-500" }} // Tailwind green-500 for the bar color
								variant="determinate"
								value={(remainingSecondsForAutoSkipQ / autoSkipQTimeoutS) * 100}
							/>
							{/* Countdown Text */}
							<Box className="text-sm text-gray-600 text-right mt-1">
								{remainingSecondsForAutoSkipQ
									? `${remainingSecondsForAutoSkipQ}s remaining to respond`
									: "Time’s up! Moving to the next question..."}
							</Box>
						</Box>
					)}
				<TimerNotifications
					currentTimeInSeconds={timerInSeconds}
					acceptedTimeLimitInMins={defaultAnswerTimeLimitInMins}
					showLastMessage={isRecordingStoppedProgramatically && wavFile}
				/>
				<Box className="text-center flex items-center justify-center space-y-6" id="mic-btn">
					{interviewEnded ? (
						<div style={{ textAlign: "center" }}>
							"Your interview is done. You will be redirected to the feedback page in a few
							seconds."
						</div>
					) : loading ? (
						<LoadingDots />
					) : !writtenPrompt && !listeningComprehension && text.length > 0 ? (
						<StyledIconButton
							size="medium"
							type="submit"
							color="primary"
							onClick={async () => {
								setLoading(true);
								const noNetwork = await ping();
								if (noNetwork) return;
								handleSend();
							}}
						>
							<SendOutlinedIcon
								style={{
									color: "rgb(35, 127, 244)",
									fontSize: "2rem",
								}}
							/>
						</StyledIconButton>
					) : (
						recentQIndex === questionIndex && (
							<Stack
								flexDirection="row"
								gap="5px"
								justifyContent={"center"}
								alignItems={"center"}
								className="relative"
							>
								{!isFirstQRef.current && !writtenPrompt && !listeningComprehension && (
									<>
										{/* {avatarMode &&
											(audioState ? (
												<Tooltip title="Mute">
													<i
														disabled={isClicking || isRecording}
														className="fas fa-volume-up cursor-pointer text-sm mr-1"
														onClick={() => {
															if (audioState) {
																audioState.pause();
																console.log(
																	`audioState paused by clicking mute for avatar`,
																);
															}
														}}
													></i>
												</Tooltip>
											) : (
												<i
													disabled={isClicking || isRecording}
													className="fas fa-volume-mute cursor-pointer text-sm mr-1"
													onClick={() => {
														console.log(
															"you can use the mute button again on the next question",
														);
													}}
												></i>
											))} */}
										<Typography
											variant="span"
											className="text-xs"
											sx={
												reRecord ||
												currentQ > 3 ||
												audioState ||
												isClicking ||
												isRecording ||
												(openingData?.isCodeEditorRequired && codeExample !== "")
													? {
															display: "none",
														}
													: {
															animation: "flash 2s infinite",

															"@keyframes flash": {
																"0%, 100%": {
																	borderColor: "transparent",
																	color: "transparent",
																},
																"50%": {
																	borderColor: "darkgray",
																	color: "black",
																},
															},
														}
											}
										>
											Click here to answer
										</Typography>
										<Box
											sx={
												reRecord ||
												currentQ > 3 ||
												audioState ||
												isClicking ||
												isRecording ||
												(openingData?.isCodeEditorRequired && codeExample !== "")
													? {}
													: {
															display: "inline-block",
															padding: "0.25rem",
															border: "4px solid transparent",
															borderRadius: "0.375rem",
															animation: "flash 2s infinite",
															marginTop: "0.25rem",
															"@keyframes flash": {
																"0%, 100%": {
																	borderColor: "transparent",
																	color: "transparent",
																},
																"50%": {
																	borderColor: "darkgray",
																	color: "black",
																},
															},
														}
											}
										>
											<Button
												disabled={audioState || isClicking || isRecording}
												type="submit"
												color="primary"
												onClick={() => {
													start();
													clearAutoSkipTimers();
													setRemainingSecondsForAutoSkipQ(0);
												}}
												className={`normal-case text-xs bg-red-500 text-white py-2 px-3 rounded hover:bg-red-600 focus:outline-none focus:border-none ${
													audioState || isRecording
														? "cursor-not-allowed opacity-50"
														: ""
												}`}
											>
												{audioState ? (
													<Box>
														<i className="fas fa-microphone mr-1"></i>
														Wait for audio to finish
													</Box>
												) : (
													<Box>
														<Box
															id="blinkingMic"
															style={{
																visibility: isRecording
																	? "visible"
																	: "hidden",
															}}
															className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex items-center justify-center gap-1  ${
																!showTimer && "hidden"
															}`}
														>
															<i className="fas fa-microphone  animate-pulse"></i>
															<Box id="timerDisplay" className=" text-sm">
																{timerContent}
															</Box>
														</Box>
														<Box
															style={{
																visibility: isRecording
																	? "hidden"
																	: "visible",
															}}
														>
															<i className="fas fa-microphone mr-1"></i>
															{reRecord ? "Re-record" : "Record Response"}
														</Box>
													</Box>
												)}
											</Button>
										</Box>
										<Button
											ref={stopRecordingButtonRef}
											disabled={isClicking || !isRecording}
											type="submit"
											color="primary"
											onClick={() => {
												// const waveform =
												// 	document.getElementById(
												// 		'waveform'
												// 	);
												// waveform.replaceChildren();
												// waveform.style.display = 'none';
												setIsClicking(true);
												stopTimer();
												setIsRecording(false);
												setReRecord(true);
												timeoutContainer = setTimeout(() => {
													stop();
												}, 1000);

												// wavesurfer('stop');
											}}
											className={`normal-case text-xs bg-red-500 text-white py-2 px-3 rounded hover:bg-red-600 focus:outline-none focus:border-none relative ${
												!isRecording && "cursor-not-allowed opacity-50"
											}`}
										>
											<Box
												style={{
													visibility: isClicking ? "hidden" : "visible",
												}}
											>
												<i className="fas fa-stop-circle mr-1"></i>
												Stop Recording
											</Box>
											<Box
												className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
												style={{
													visibility: isClicking ? "visible" : "hidden",
												}}
											>
												<i className="fas fa-circle-notch fa-spin mr-1"></i>
											</Box>
										</Button>
									</>
								)}
								{isFirstQRef.current ? (
									<Button
										disabled={loading || audioState}
										type="submit"
										color="primary"
										onClick={async () => {
											setLoading(true);
											const noNetwork = await ping();
											if (noNetwork) return;
											handleSend("begin");
										}}
										className={`normal-case text-xs bg-blue-500 text-white py-2 px-3 rounded hover:bg-blue-600 focus:outline-none focus:border-none  ${
											loading || audioState ? "cursor-not-allowed opacity-50" : ""
										}`}
									>
										<Box>
											<i className="fas fa-arrow-right mr-1"></i>
											Begin{" "}
										</Box>
									</Button>
								) : (
									<Box
										sx={
											currentQ > 3 ||
											(!writtenPrompt && !listeningComprehension
												? wavFile
													? false
													: openingData?.isCodeEditorRequired
														? codeExample === ""
														: true
												: writtenPrompt
													? text.length < 1
													: listeningComprehension &&
														listQuestionsAnswered < listQuestionsCount)
												? {}
												: {
														padding: "3px",
														border: "3px solid transparent",
														borderRadius: "0.375rem",
														animation: "flash 2s infinite",
														"@keyframes flash": {
															"0%, 100%": {
																borderColor: "transparent",
															},
															"50%": {
																borderColor: "darkgray",
															},
														},
													}
										}
									>
										<Button
											disabled={
												!writtenPrompt && !listeningComprehension
													? wavFile
														? false
														: openingData?.isCodeEditorRequired
															? codeExample === ""
															: true
													: writtenPrompt
														? text.length < 1
														: listeningComprehension &&
															listQuestionsAnswered < listQuestionsCount
											}
											type="submit"
											color="primary"
											onClick={async () => {
												clientLogger("Submit response button clicked by candidate.");
												setLoading(true);
												const noNetwork = await ping();
												if (noNetwork) {
													clientLogger("Ping failed, poor internet detected", {
														level: "error",
													});
													return;
												}
												clientLogger("Ping successful before submitting answer.");
												if (writtenPrompt && !listeningComprehension) {
													handleSend();
												} else {
													listeningComprehension
														? processAudioForListeningComprehension()
														: openingData?.isCodeEditorRequired
															? wavFile
																? processAudio()
																: handleSend()
															: processAudio();
												}
											}}
											className={`normal-case text-xs bg-blue-500 text-white py-2 px-3 rounded hover:bg-blue-600 focus:outline-none focus:border-none  ${
												!writtenPrompt && !listeningComprehension
													? (wavFile
															? false
															: openingData?.isCodeEditorRequired
																? codeExample === ""
																: true) && "cursor-not-allowed opacity-50"
													: writtenPrompt
														? text.length < 1 && "cursor-not-allowed opacity-50"
														: listeningComprehension &&
															listQuestionsAnswered < listQuestionsCount &&
															"cursor-not-allowed opacity-50"
											}`}
										>
											<Box>
												<i className="fas fa-arrow-right mr-1"></i>
												Submit{" "}
												{!listeningComprehension &&
													responseLength &&
													`(${responseLength})`}
											</Box>
										</Button>
									</Box>
								)}
							</Stack>
						)
					)}
				</Box>
				{!isFirstQRef.current && recentQIndex === questionIndex && (
					<Box className="flex justify-center mt-4 space-x-4">
						<Link
							className={
								!interviewEnded &&
								messages.length > 1 &&
								!loading &&
								!audioState &&
								(autoSkipQTimeoutS ? !remainingSecondsForAutoSkipQ : true)
									? "text-blue-500"
									: "text-gray-500"
							}
							onClick={async () => {
								if (autoSkipQTimeoutS && remainingSecondsForAutoSkipQ) {
									// console.log("Clicked skip button while auto skip is active");
									return;
								}
								if (messages.length > 1 && !loading && !audioState) {
									setLoading(true);
									const noNetwork = await ping();
									if (noNetwork) return;
									handleSend("skip");
									if (audioState) {
										audioState.pause();
									}
								}
							}}
							sx={{
								textDecoration: "none",
								lineHeight: "1rem",
								fontSize: "0.75rem",
								cursor:
									!interviewEnded &&
									messages.length > 1 &&
									!loading &&
									!audioState &&
									(autoSkipQTimeoutS ? !remainingSecondsForAutoSkipQ : true)
										? "pointer"
										: "default",
								":hover": {
									textDecoration:
										!interviewEnded &&
										messages.length > 1 &&
										!loading &&
										!audioState &&
										(autoSkipQTimeoutS ? !remainingSecondsForAutoSkipQ : true)
											? "underline"
											: "none",
								},
							}}
						>
							<Box>
								{autoSkipQTimeoutS > 0 && remainingSecondsForAutoSkipQ > 0
									? `You have ${autoSkipQTimeoutS} seconds to respond. If no response is recorded, the next question will begin automatically.`
									: "Skip the question"}
							</Box>
							{/* {remainingSecondsForAutoSkipQ
								? `Click 'Record Response' within the next ${remainingSecondsForAutoSkipQ} seconds to avoid skipping this question.`
								: "Skip the question"} */}
						</Link>
						{openingData?.isTechnical && openingData?.isCodeEditorRequired && (
							<Link
								className={
									!interviewEnded && messages.length > 1 && !loading
										? "text-blue-500"
										: "text-gray-500"
								}
								onClick={() => {
									if (messages.length > 1 && !loading) {
										setShowIDE((prevShow) => !prevShow);
									}
									// else {
									// 	console.log(
									// 		"Tried to open code editor on first question or while loading",
									// 	);
									// }
								}}
								sx={{
									textDecoration: "none",
									lineHeight: "1rem",
									fontSize: "0.75rem",
									cursor:
										!interviewEnded && messages.length > 1 && !loading
											? "pointer"
											: "default",
									":hover": {
										textDecoration:
											!interviewEnded && messages.length > 1 && !loading
												? "underline"
												: "none",
									},
								}}
							>
								{showIDE ? "Hide" : "Show"} code editor
							</Link>
						)}
					</Box>
				)}
			</Box>
		);
	};

	function FormattedMessage({ message }) {
		const codeStringIndex = message.indexOf("```");
		const codeString =
			codeStringIndex !== -1 ? message.substring(codeStringIndex + 3, message.length - 3) : "";
		const responseMessage = codeStringIndex === -1 ? message : message.substring(0, codeStringIndex - 1);

		return (
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					gap: "0.5rem",
					whiteSpace: "pre-line",
				}}
			>
				{responseMessage}
				{codeString && (
					<div>
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
								backgroundColor: "#337CCF",
								borderRadius: "10px 10px 0 0",
								paddingInline: "10px",
							}}
						>
							<span style={{ color: "white", padding: "5px 3px" }}>Code</span>
						</div>
						<SyntaxHighlighter
							language={"javascript"}
							style={googlecode}
							customStyle={{
								padding: "2rem",
								borderRadius: "0 0 10px 10px",
							}}
						>
							{codeString.trim()}
						</SyntaxHighlighter>
					</div>
				)}
			</Box>
		);
	}

	const _chatResponseHelper = (messageContent, { ref, loading, chatCompletionWaiter = false } = {}) => {
		return (
			<ChatMessageContainer>
				<DropBoxContainer ref={ref}>
					{messageContent}
					{chatCompletionWaiter && showBlinker && <BlinkingContainer />}
				</DropBoxContainer>
			</ChatMessageContainer>
		);
	};

	const toggleContent = () => {
		setShowContent(!showContent);
	};

	const renderAssistantChatMessage = (messageContent, { loading }) => {
		return (
			<ChatMessageContainer>
				<div>
					<div
						style={{
							display: "flex",
							alignItems: "center",
						}}
					>
						<div style={{ display: "flex" }}>
							<Button
								startIcon={loading ? <CircularProgress size={12} /> : null}
								onClick={toggleContent}
							>
								{showContent ? (
									<span>
										Hide Content <ExpandLessIcon />
									</span>
								) : (
									<span>
										Show Content <ExpandMoreIcon />
									</span>
								)}
							</Button>
						</div>
					</div>
					<br />
					<Collapse in={showContent}>
						<DropBoxContainer
							style={{
								height: "10rem", // Set the desired height
								overflow: "auto", // Enable scrolling when content exceeds the height
								padding: "10px",
							}}
						>
							{messageContent}
							{loading && <BlinkingContainer />}
						</DropBoxContainer>
					</Collapse>
				</div>
			</ChatMessageContainer>
		);
	};

	const renderChatMessageContainer = (message, { isAssistant, role, index, isLatestMessageFromUser }) => {
		if (isAssistant) {
			return _chatResponseHelper(message.content);
		}

		const codeStringIndex = message.content.indexOf("```");
		const codeString =
			codeStringIndex !== -1
				? message.content.substring(codeStringIndex + 3, message.content.length - 3)
				: "";
		const responseMessage =
			codeStringIndex === -1 ? message.content : message.content.substring(0, codeStringIndex - 1);
		if (editModeForMessage && isLatestMessageFromUser) {
			return (
				<TextField
					multiline={true}
					minRows={3}
					maxRows={5}
					value={latestUserMessage}
					fullWidth
					helperText={"Press Shift+Enter to submit"}
					onContextMenu={(e) => {
						e.preventDefault();
					}}
					onDragOver={(e) => {
						e.preventDefault();
					}}
					onKeyDown={(e) => {
						if (e.shiftKey && e.key?.toLowerCase() === "enter") {
							sendUpdatedMessagesForCompletion();
						}
						if (e.ctrlKey && e.key?.toLowerCase() === "v") {
							e.preventDefault();
						}
					}}
					onChange={(e) => setLatestUserMessage(e.target.value)}
				/>
			);
		}
		return (
			<ChatMessageContainer>
				{responseMessage}
				{codeStringIndex != -1 && codeString.length > 0 && (
					<div>
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
								backgroundColor: "#337CCF",
								borderRadius: "10px 10px 0 0",
								paddingInline: "10px",
							}}
						>
							<span style={{ color: "white", padding: "5px 3px" }}>Code</span>
						</div>
						<SyntaxHighlighter
							language={"javascript"}
							style={googlecode}
							customStyle={{
								padding: "2rem",
								borderRadius: "0 0 10px 10px",
							}}
						>
							{codeString.trim()}
						</SyntaxHighlighter>
					</div>
				)}
			</ChatMessageContainer>
		);
	};

	const renderChatBox = (children, { role, isAssistant, isLatestMessageFromUser = false, rawMessage }) => {
		return (
			<ChatBox
				style={
					isLatestMessageFromUser
						? {
								backgroundColor: "#ffffff",
								boxShadow: "0px 3px 4px rgba(0,0,0,0.4)",
								borderRadius: "3px",
							}
						: {}
				}
			>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						gap: 6,
						paddingRight: "0.7rem",
						paddingBottom: "0.5rem",
						justifyContent: "space-between",
						// width: '100%',
					}}
				>
					<Stack
						flexDirection={"row"}
						alignItems={"center"}
						justifyContent={"space-between"}
						gap={"10px"}
						width={"100%"}
					>
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								gap: "6px",
							}}
						>
							<div
								style={{
									backgroundColor: isAssistant ? "#237ff4" : "grey",
									height: 28,
									width: 28,
									borderRadius: 3,
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
								}}
							>
								{/* {isAssistant ? assistantLogo : null} */}
							</div>

							<div
								style={{
									color: isAssistant ? "#237ff4" : "grey",
									fontSize: "0.875rem",
									textTransform: "uppercase",
								}}
							>
								{isAssistant ? "Assistant" : "USER"}
							</div>
						</Box>
					</Stack>

					{editResponseOption && isLatestMessageFromUser && !isAssistantLoading && (
						<Stack
							flexDirection={"row"}
							alignItems={"center"}
							justifyContent={"center"}
							gap={"10px"}
						>
							<Tooltip title="Speak again">
								<IconButton
									onClick={(e) => {
										redoLastMessages();
										setTimeout(() => {
											start();
											// wavesurfer();
										}, 1000);
									}}
								>
									<MicIcon />
								</IconButton>
							</Tooltip>
							<Tooltip title={`${editModeForMessage ? "Save Response" : "Edit Response"}`}>
								<IconButton
									onClick={(e) => {
										if (editModeForMessage === true) {
											handleSaveLatestMessage();
										}
										setEditModeForMessage((prev) => !prev);
									}}
								>
									{editModeForMessage ? <CheckIcon /> : <ModeEditIcon />}
								</IconButton>
							</Tooltip>
							<Tooltip title="Submit Response">
								<IconButton
									onClick={(e) => {
										sendUpdatedMessagesForCompletion();
									}}
								>
									<SendIcon
										sx={{
											color: "rgb(35, 127, 244)",
										}}
									/>
								</IconButton>
							</Tooltip>
						</Stack>
					)}
				</div>
				{children}
			</ChatBox>
		);
	};

	//Text to Speech

	// const rhubarb = (text) => {
	// 	if (
	// 		text === '' ||
	// 		text === null ||
	// 		text === undefined ||
	// 		text === 'undefined'
	// 	) {
	// 		console.log('text is empty');
	// 		return;
	// 	}
	// 	const executeAsync = async () => {
	// 		try {
	// 			const response = await fetch(MEETING_SERVICE, {
	// 				method: 'POST',
	// 				headers: new Headers(),
	// 				body: JSON.stringify({
	// 					action: 'TEXT_TO_SPEECH2',
	// 					text: text,
	// 				}),
	// 			});
	// 			const data = await response.json();
	// 			const audioBlob = new Blob(
	// 				[
	// 					new Uint8Array(
	// 						atob(data.audioContent)
	// 							.split('')
	// 							.map((char) => char.charCodeAt(0))
	// 					),
	// 				],
	// 				{
	// 					type: 'audio/wav',
	// 				}
	// 			);
	// 			// const wavFile = new File([audioBlob], 'rhubarb.wav', {
	// 			// 	type: 'audio/wav',
	// 			// });
	// 			// const rhubarbResponse = await handleRhubarb(wavFile);

	// 			// const lipSyncData = await rhubarbResponse.json();
	// 			// setLipSync(rhubarbResponse.data);

	// 			// LAMBDA TEST
	// 			// const response2 = await fetch(MEETING_SERVICE_TEST, {
	// 			// 	method: 'POST',
	// 			// 	headers: new Headers(),
	// 			// 	body: JSON.stringify({
	// 			// 		action: 'RHUBARB',
	// 			// 		audioContent: data.audioContent,
	// 			// 	}),
	// 			// });
	// 			// const data2 = await response2.json();
	// 			// setLipSync(data2.rhubarbOutput);
	// 			// LAMBDA TEST
	// 			// console.log(
	// 			// 	`rhubarbResponse: ${rhubarbResponse} and rhubarbResponse.data.mouthCues: ${rhubarbResponse.data.mouthCues}`
	// 			// );

	// 			// setLipSync(rhubarbResponse.data);
	// 			const audioUrl = URL.createObjectURL(audioBlob);
	// 			const audio = new Audio(audioUrl);
	// 			setAudioState(audio);
	// 			audio.play();
	// 			audio.onended = () => {
	// 				setAudioState(null);
	// 				setShowPlayButton && setShowPlayButton(true);
	// 			};
	// 			audio.onpause = () => {
	// 				setAudioState(null);
	// 			};

	// 			setLoading(false);
	// 		} catch (err) {
	// 			console.error('Error during rhubarb: ' + err);
	// 		}
	// 	};

	// 	executeAsync();
	// };

	const processTextForAudio = (text, action) => {
		// console.log("text", text);
		if (text === "" || text === null || text === undefined || text === "undefined") {
			clientLogger("Could not initiate audio synthesis as text received as empty.");
			console.log("text is empty");
			return;
		}
		const executeAsync = async () => {
			try {
				clientLogger(
					"Synthesizing response/question from the interviewer. Calling Text to Speech endpoint.",
					{
						text: text,
						action: action,
					},
				);
				const textToSend = stripMarkdown(text);
				const response = await textToSpeechGC(
					openingData?.languageOfQuestions ?? "en",
					textToSend,
					userInterviewReportId || params?.interviewReportId || "",
				);
				const audioBlob = new Blob(
					[
						new Uint8Array(
							atob(response?.audioContent)
								.split("")
								.map((char) => char.charCodeAt(0)),
						),
					],
					{
						type: "audio/mp3",
					},
				);

				const audioUrl = URL.createObjectURL(audioBlob);
				const audio = new Audio(audioUrl);
				setAudioState(audio);
				if (action === "avatar") {
					setLoading(false);
				} else {
					audio.play();
					clientLogger("Playing question for the candidate to listen to.");
				}
				audio.onended = () => {
					// alert(
					// 	`autoSkipQTimeoutS: ${autoSkipQTimeoutS}, isFirstQRef.current: ${isFirstQRef.current}, NoAutoSkip: ${NoAutoSkip}`,
					// );
					if (autoSkipQTimeoutS !== 0 && !isFirstQRef.current && !NoAutoSkipRef.current) {
						clearAutoSkipTimers();
						startAutoSkipTimers();
					}
					clientLogger("Audio ended and candidate is allowed to answer the question.");
					setAudioState(null);
					setShowPlayButton && setShowPlayButton(true);
				};
				audio.onpause = () => {
					setAudioState(null);
				};
			} catch (err) {
				console.error(
					`Error during executeAsync in processTextForAudio with error: ${err} and action: ${action}`,
				);
			}
		};

		executeAsync();
	};

	const renderChatMessage = (message, { role, isAssistant, index, isLatestMessageFromUser }) => {
		const rawMessage = JSON.stringify(message.content);

		return (
			<ChatContainer role={role} key={index}>
				{renderChatBox(
					renderChatMessageContainer(message, {
						isAssistant,
						role,
						index,
						isLatestMessageFromUser,
					}),
					{ role, isAssistant, isLatestMessageFromUser, rawMessage },
				)}
			</ChatContainer>
		);
	};

	const renderChatStreamResponse = () => {
		return (
			<ChatContainer role="ASSISTANT">
				{renderChatBox(
					_chatResponseHelper("", {
						loading,
						chatCompletionWaiter: true,
					}),
					{
						isAssistant: true,
						role: "ASSISTANT",
					},
				)}
			</ChatContainer>
		);
	};

	const renderChatMessages = () => {
		//ChatsContainer -> ChatContainer -> ChatBox -> ChatMessageContainer
		return (
			<ChatsContainer>
				{messages.map((message, index) => {
					const role = message.role.toUpperCase();
					const isAssistant = role === "ASSISTANT" || role === "FUNCTION";
					if (isAssistant && message.content === activeMessage) {
						return null;
					}
					if (isAssistant) return null;
					return renderChatMessage(message, {
						role,
						isAssistant,
						index,
						isLatestMessageFromUser:
							index === messages?.length - 1 && role.toLowerCase() === "user",
					});
				})}
				<div ref={messagesEndRef} />
			</ChatsContainer>
		);
	};

	useEffect(() => {
		if (true) {
			//   resetResponse();
			inputRef.current?.focus();
		}
	}, []);

	const handleSnackBarClose = (e) => {
		setSnackBarOpen(false);
	};

	const action = (
		<React.Fragment>
			<IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackBarClose}>
				<CloseIcon fontSize="small" />
			</IconButton>
		</React.Fragment>
	);
	return (
		<>
			{/* {(openingData && openingData?.proctoring) && interviewReportData?.preferredName && (
				<VideoTilesterviewE
					preferredName={interviewReportData?.preferredName}
					interviewEnded={innded}
					existingMeetingId={interviewReportData.meetingId}
				/>
			)} */}
			{/* <VideoTiles /> */}
			{/* {showTextToggle ? renderChatTitle() : renderChatTitleMain()} */}
			{/* <DrawerContainer ref={chatContainerRef}>
				{renderDrawerContainer()}
			</DrawerContainer> */}
			{/* {console.log(`interviewEnded before VideoTiles: ${interviewEnded}`)} */}

			{renderChatInput()}
			<Snackbar
				open={snackbarOpen}
				autoHideDuration={6000}
				onClose={handleSnackBarClose}
				message={snackBarMessage}
				action={action}
			/>
		</>
	);
};

export default ChatDialog;

const dotsAnimation = keyframes`
  0%,
  20% {
    color: #0000;
    text-shadow: 0.25em 0 0 #0000, 0.5em 0 0 #0000;
  }

  40% {
    color: black;
    text-shadow: 0.25em 0 0 #0000, 0.5em 0 0 #0000;
  }

  60% {
    text-shadow: 0.25em 0 0 black, 0.5em 0 0 #0000;
  }

  80%,
  100% {
    text-shadow: 0.25em 0 0 black, 0.5em 0 0 black;
  }

  80%,
  100% {
    text-shadow: 0.25em 0 0 black, 0.5em 0 0 black;
  }
`;

const blinking = keyframes`
  0% {
    opacity: 0;
  }
`;
const BlinkingContainer = styled.div`
	& {
		&::after {
			font-size: 16px;
			animation: ${blinking} 1s steps(2) infinite;
			content: "▋";
			vertical-align: baseline;
		}
	}
`;

const LoadingDots = styled.div`
	&& {
		width: 2rem;
		height: 2rem;

		&::after {
			content: " •";
			font-size: 1.325rem;
			animation: ${dotsAnimation} 1s steps(5, end) infinite;
		}
	}
`;

const DrawerContainer = styled.div`
	&& {
		display: flex;
		flex-direction: column;
		flex: 1;
		overflow-y: auto;

		&::-webkit-scrollbar {
			width: 4px;
			height: 0px;
		}

		&::-webkit-scrollbar-thumb {
			background-color: gray;
			border-radius: 4px;
		}
	}
`;

const DropBoxContainer = styled.div`
	&& {
		&::-webkit-scrollbar {
			width: 4px;
			height: 0px;
		}

		&::-webkit-scrollbar-thumb {
			background-color: gray;
			border-radius: 4px;
		}
	}
`;

const StyledIconButton = styled(IconButton)`
	&& {
	}
`;

const ActionContainer = styled.div`
	&& {
		position: absolute;
		right: 0;
		bottom: 0.5rem;
		padding: 0.25rem;
	}
`;

const ChatInput = styled(TextareaAutosize)`
	&& {
		font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
			sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
		width: 100%;
		padding: ${(props) => (props.writtenPrompt ? "0.5rem" : "1rem 4rem 1rem 1.5rem")};
		font-size: 16px !important;
		border-radius: ${(props) => (props.writtenPrompt ? "0.375rem" : "12px")};
		/* border: 1px solid lightgray; */
		border: ${(props) => (props.writtenPrompt ? "1px solid" : "1px solid lightgray")};
		/* resize: none; */
		resize: ${(props) => (props.writtenPrompt ? "vertical" : "none")};
		outline: none;

		&::-webkit-scrollbar {
			width: 4px;
			height: 0px;
			cursor: pointer;
		}

		&::-webkit-scrollbar-thumb {
			background-color: lightgray;
			border-radius: 4px;
			cursor: pointer;
		}

		&:disabled {
			color: gray;
		}
	}
`;

const ChatsContainer = styled.div`
	& {
		display: flex;
		flex-direction: column;
	}
`;

// const ChatContainer = styled.div`
//   & {
//     padding-left: ${(prop) => (prop.role === "user" ? "50%" : 0)};
//     padding-right: ${(prop) => (prop.role === "assistant" ? "50%" : 0)};
//   }
// `;

const ChatContainer = styled.div`
	& {
		display: flex;
		background-color: ${(props) => (props.role === "ASSISTANT" ? "#f7f7f8" : "none")};
		width: 100%;
		border-bottom: 1px solid #dbdbdb;
		padding: 0.5rem;
	}
`;

const ChatBox = styled.div`
	& {
		padding: 1rem;
		width: 100%;
	}
`;

const ChatMessageContainer = styled.div`
	& {
		width: 100%;
		white-space: pre-wrap;
		font-size: 0.875rem;
	}
`;

const TimerNotifications = ({
	currentTimeInSeconds,
	acceptedTimeLimitInMins = 7,
	showLastMessage = false,
}) => {
	const lastTwoMinutes = (acceptedTimeLimitInMins - 2) * 60;
	const lastOneMinute = (acceptedTimeLimitInMins - 1) * 60;
	const acceptedTimeLimitInSecs = acceptedTimeLimitInMins * 60;
	if (typeof currentTimeInSeconds === "number" && !isNaN(currentTimeInSeconds)) {
		if (currentTimeInSeconds > lastTwoMinutes && currentTimeInSeconds <= lastOneMinute) {
			const remainingTimeInSeconds = acceptedTimeLimitInMins * 60 - currentTimeInSeconds;
			const minutes = Math.floor(remainingTimeInSeconds / 60);
			const seconds = remainingTimeInSeconds % 60;
			return (
				<div className="mt-2 mb-4 flex flex-col md:flex-row gap-3 justify-between items-center">
					<div className="text-xs font-bold text-gray-600 px-10 rounded-lg">{`Time Remaining: ${minutes}:${String(seconds).padStart(2, "0")}`}</div>
				</div>
			);
		} else if (currentTimeInSeconds > lastOneMinute && currentTimeInSeconds <= acceptedTimeLimitInSecs) {
			const remainingTimeInSeconds = acceptedTimeLimitInMins * 60 - currentTimeInSeconds;
			const minutes = Math.floor(remainingTimeInSeconds / 60);
			const seconds = remainingTimeInSeconds % 60;
			return (
				<div className="mt-2 mb-4 flex flex-col md:flex-row gap-3 justify-between items-center">
					<div className="text-xs font-bold text-red-500 px-10 rounded-lg">{`Time Remaining: ${minutes}:${String(seconds).padStart(2, "0")}`}</div>
					<div id="timeWarning" className="text-xs font-bold">
						Hurry up, you're almost out of time!
					</div>
				</div>
			);
		} else if (showLastMessage) {
			return (
				<div className="mt-2 mb-4 flex flex-col md:flex-row gap-3 justify-between items-center">
					<div className="text-xs font-bold text-gray-600 px-10 rounded-lg">Time's up!</div>
					<div id="timeWarning" className="text-xs font-bold">
						Submit your response or re-record. It's okay!
					</div>
				</div>
			);
		}
	}
};
