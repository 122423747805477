// renderers
import { useEffect, useState } from "react";
import ActionsColumn from "./columnRenderer/ActionsColumnRenderer";
import CandidateDetailsRenderer from "./columnRenderer/CandidateDetailsRenderer";
import CustomFieldsRenderer from "./columnRenderer/CustomColumnsRenderer";
import DateRenderer from "./columnRenderer/DateRenderer";
import ScheduleRenderer from "./columnRenderer/InterviewScheduleRenderer";
import InterviewStatusRenderer from "./columnRenderer/InterviewStatusRenderer";
import LogsRenderer from "./columnRenderer/LogsColumnRenderer";
import RecordingRenderer from "./columnRenderer/RecordingsRenderer";
import ResumeRenderer from "./columnRenderer/ResumeColumnRenderer";
import ScoreRenderer from "./columnRenderer/ScoreRenderer";
import TrustScoreRenderer from "./columnRenderer/TrustScoreRenderer";
import ViewReportRenderer from "./columnRenderer/ViewReportRenderer";
import dateColumnFilter from "./columnRenderer/filters/DateColumnFilter";
import dropDownFilter from "./columnRenderer/filters/DropDownColumnFilter";

// filters
import getNumberColumnFilters from "./columnRenderer/filters/NumberColumnFilter";
import stringColumnFilter from "./columnRenderer/filters/StringColumnFilter";
import { getCustomFieldComparator, getInterviewDate, getInterviewStatus, getRecordingLink, getResumeLink, getScore, getTrustScore, getValuesForCustomFields, interviewStatusComparator, nameColumnComparator, stringComparator } from "./columnRenderer/filters/valueGetters";

// sorters
import { dateSorter, numberSorter } from "./columnRenderer/filters/valuesSorter";
import { setColumnsOrder } from "../../features/createOpening/createOpeningSlice";
import { useDispatch, useSelector } from "react-redux";
import { saveColumnOrder } from "../../utilities/openingsApi";

const useGetColumnsAndRenderers = () => {
	const dispatch = useDispatch();
	const { user } = useSelector((state) => state.auth);
	const { selectedOpening } = useSelector((state) => state.openingDetails);

	let initialColumns = [
		{
			field: "Actions",
			headerName: "",
			headerClassName: "bg-[#F3F4F6] text-xs font-medium uppercase text-gray-500",
			width: 50,
			renderCell: (params) => <ActionsColumn params={params} />,
			headerAlign: "center",
			sortable: false,
			filterable: false,
			disableColumnMenu: true,
		},
		{
			field: "createdAt",
			headerName: "Interview Date",
			headerClassName: "bg-[#F3F4F6] border-left text-xs font-medium uppercase text-gray-500",
			width: 150,
			renderCell: (params) => <DateRenderer params={params} />,
			filterOperators: dateColumnFilter(getInterviewDate),
			sortComparator: dateSorter,
		},
		{
			field: "Name",
			headerName: "Name",
			headerClassName: "bg-[#F3F4F6] border-left text-xs font-medium uppercase text-gray-500",
			width: 200,
			renderCell: (params) => <CandidateDetailsRenderer params={params} openingData={selectedOpening} />,
			sortable: true,
			filterOperators: stringColumnFilter(nameColumnComparator),
			valueGetter: (_, row) => `${row.firstName} ${row.lastName}`
		},
		{
			field: "score",
			headerName: "Score",
			headerClassName: "bg-[#F3F4F6] border-left text-xs font-medium uppercase text-gray-500",
			width: 150,
			// headerAlign: "center",
			renderCell: (params) => <ScoreRenderer params={params} />,
			filterOperators: getNumberColumnFilters(getScore),
			sortComparator: numberSorter,
			valueGetter: (_, row) => getScore(row)
		},
		{
			field: "trustScore",
			headerName: "Trust Score",
			headerClassName: "bg-[#F3F4F6] border-left text-xs font-medium uppercase text-gray-500",
			width: 150,
			// headerAlign: "center",
			renderCell: (params) => <TrustScoreRenderer params={params} />,
			filterOperators: getNumberColumnFilters(getTrustScore),
			sortComparator: numberSorter,
			valueGetter: (_, row) => getTrustScore(row)
		},
		{
			field: "interviewStatus",
			headerName: "Interview Status",
			headerClassName: "bg-[#F3F4F6] border-left text-xs font-medium uppercase text-gray-500",
			width: 360,
			renderCell: (params) => <InterviewStatusRenderer params={params} openingData={selectedOpening} />,
			// headerAlign: "center",
			sortable: false,
			filterOperators: dropDownFilter((a, b) => interviewStatusComparator(a, b, selectedOpening), [], true),
			valueGetter: (_, row) => getInterviewStatus({ ...row, opening: selectedOpening }),
		},
		{
			field: "viewReport",
			headerName: "View Report",
			headerClassName: "bg-[#F3F4F6] border-left text-xs font-medium uppercase text-gray-500",
			width: 110,
			renderCell: (params) => <ViewReportRenderer params={params} openingData={selectedOpening} />,
			headerAlign: "center",
			sortable: false,
			filterable: false,
			disableColumnMenu: true,
		},
	];

	const generateColumns = (customFields) => {
		let columns = [...initialColumns];
		customFields?.forEach((field) => {
			let options = [];
			if (field.type == 1) {
				field.config?.dropDownValues?.forEach((val) => {
					options.push({
						value: val.id,
						id: val.id,
						label: val.value,
						disabled: false,
					});
				});
			}
			columns.push({
				field: field._id,
				headerName: field.fieldName,
				headerClassName: "bg-[#F3F4F6] border-left text-xs font-medium uppercase text-gray-500",
				width: 200,
				renderCell: (params) => <CustomFieldsRenderer params={params} id={field._id} field={field} />,
				sortable: false,
				filterOperators: field.type == 1 ?
					dropDownFilter((a, b) => stringComparator(a.customFieldsData, b, 'is', field._id), options) :
					stringColumnFilter((a, b, c) => getCustomFieldComparator(getValuesForCustomFields(a, field._id, field.type, selectedOpening), b, c, field._id)),
				valueGetter: (_, row) => getValuesForCustomFields(row, field._id, field.type, selectedOpening),
			});
		});

		return columns;
	};

	const appendStaticColumns = (columns) => {
		let staticColumns = [
			{
				field: "resumeFileNameIsS3",
				headerName: "Resume",
				headerClassName: "bg-[#F3F4F6] border-left text-xs font-medium uppercase text-gray-500",
				width: 100,
				renderCell: (params) => <ResumeRenderer params={params} />,
				headerAlign: "center",
				sortable: false,
				filterable: false,
				disableColumnMenu: true,
				valueGetter: (_, row) => getResumeLink(row),
			},
			{
				field: "schedule",
				headerName: "Interview Schedule",
				headerClassName: "bg-[#F3F4F6] border-left text-xs font-medium uppercase text-gray-500",
				width: 280,
				renderCell: (params) => <ScheduleRenderer params={params} openingData={selectedOpening} />,
				headerAlign: "center",
				sortable: false,
				filterable: false,
				disableColumnMenu: true,
			},
			{
				field: "concatenationId",
				headerName: "Recording",
				headerClassName: "bg-[#F3F4F6] border-left text-xs font-medium uppercase text-gray-500",
				width: 150,
				renderCell: (params) => <RecordingRenderer params={params} />,
				headerAlign: "center",
				sortable: false,
				filterable: false,
				disableColumnMenu: true,
				valueGetter: (_, row) => getRecordingLink(row),
			},
		];

		if (user?.role === "1096") {
			staticColumns.push({
				field: "logs",
				headerName: "Logs",
				headerClassName: "bg-[#F3F4F6] border-left text-xs font-medium uppercase text-gray-500",
				width: 100,
				renderCell: (params) => <LogsRenderer params={params} />,
				headerAlign: "center",
				sortable: false,
				filterable: false,
				disableColumnMenu: true,
			});
		}
		return [...columns, ...staticColumns];
	};

	const [cols, setCols] = useState(generateColumns(selectedOpening?.customFields || []));
	const [draggedCol, setDraggedCol] = useState(null);

	useEffect(() => {
		let newColumns = generateColumns(selectedOpening?.customFields || []);
		newColumns = appendStaticColumns(newColumns);
		let columnOrder = selectedOpening?.columns;
		if (columnOrder && Array.isArray(columnOrder) && columnOrder.length > 0) {
			let columnNames = columnOrder.map((el) => typeof el === "object" ? el.field : el);
			newColumns = newColumns.sort((a, b) => {
				let aIndex = columnNames.indexOf(a.field);
				let bIndex = columnNames.indexOf(b.field);
				if (aIndex < 0) aIndex = newColumns.length;
				if (bIndex < 0) bIndex = newColumns.length;
				return aIndex - bIndex;
			});
		}
		setCols(newColumns);
	}, [selectedOpening]);

	const handleDragStart = (col) => {
		setDraggedCol(col);
	};

	const handleDrop = (targetCol) => {
		if (!draggedCol) return;

		const draggedIndex = cols.findIndex((col) => col.field === draggedCol.field);
		const targetIndex = cols.findIndex((col) => col.field === targetCol.field);

		const newColumns = [...cols];
		newColumns.splice(draggedIndex, 1);
		newColumns.splice(targetIndex, 0, draggedCol);

		setCols(newColumns);
		setDraggedCol(null);
		dispatch(setColumnsOrder(JSON.parse(JSON.stringify(newColumns.map((el) => ({ field: el.field }))))));
		saveColumnOrder(selectedOpening._id, newColumns.map(el => ({ field: el.field })));
	};

	const handleDragOver = (e, col, index) => {
		e.preventDefault();
		if (!draggedCol) return;

		const draggedIndex = cols.findIndex((column) => column.field === draggedCol.field);
		if (draggedIndex === index) return;

		const newColumns = [...cols];
		newColumns.splice(draggedIndex, 1);
		newColumns.splice(index, 0, draggedCol);

		setCols(newColumns);
	};

	return cols.map((col, index) => ({
		...col,
		renderHeader: (params) => (
			<div
				draggable={col.field === "Actions" ? false : true}
				onDragStart={
					col.field === "Actions" ? () => null : () => handleDragStart(col, index)
				}
				onDragOver={
					col.field === "Actions" ? () => null : (e) => handleDragOver(e, col, index)
				}
				onDrop={col.field === "Actions" ? () => null : () => handleDrop(col)}
				style={{ cursor: col.field === "Actions" ? "" : "grab" }}
				className="font-semibold flex flex-1 h-full w-full align-middle items-center transition-all"
			>
				{params.colDef.headerName}
			</div>
		),
	}));
};

export default useGetColumnsAndRenderers;
